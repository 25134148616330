import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as TwitterlogoW } from "../public/images/twitterlogwhite.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import VoteTxnDiv from "./votetxndiv";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

function PopClaim(props) {
  const { sessionEnded, tokenNative } = UseContractInfo();

  const [isOpen, setIsOpen] = useState(props.openPopup);
  const txnPopup = props.txnPopup;

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [closeConfirmPopup, setCloseConfirmPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [, updateState] = useState();
  const forceRender = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (closeConfirmPopup) {
      setTransactionSuccess(null);
    }
  }, [closeConfirmPopup]);

  async function handleClaim() {
    var bribeArray = {
      submissionId: txnPopup.submissionId,
    };
    const { status, message } = await props.handleClaim(bribeArray);
    setTransactionSuccess(status);
    setGettingReceipt(false);
    if (status === true) {
      props.success(true);
    } else {
      setErrorMessage(message);
    }
  }

  return (
    <>
      {isOpen && (
        <>
          {/* {!sessionEnded && (
            <>
              <div
                // fixed z-20 ml-[-1080.5px] mt-0
                className="fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80"
                onClick={(e) => {
                  props.closepopup(false);
                }}
              >
                <div
                  className="relative bg-[#110a26] bg-opacity-100 w-full md:w-[900px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
                  onClick={(e) => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                  }}
                >
                  <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-col items-center justify-center">
                    <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                      Session is Live, Can't claim yet
                    </p>
                  </div>
                  <div className="flex items-center justify-center py-8 space-x-12">
                    <button
                      className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                      onClick={() => props.closepopup(false)}
                    >
                      <p className="text-[16px] font-bold">Go back</p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )} */}
          {/* {sessionEnded && ( */}
          <>
            <div
              className="fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80"
              onClick={() => props.closepopup(false)}
            >
              <div
                className="relative bg-[#110a26] bg-opacity-100 w-full md:w-[840px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#ff31e9]"
                onClick={(e) => {
                  // do not close modal if anything inside modal content is clicked
                  e.stopPropagation();
                }}
              >
                <button
                  className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
                  onClick={() => props.closepopup(false)}
                >
                  <p className="z-0 mt-[-2px] uppercase font-bold text-white">
                    x
                  </p>
                </button>
                <div className="relative w-full border-b-[1px] border-b-gray-400 flex items-center justify-center">
                  {txnPopup.eligibleEarning > 0 ? (
                    <h1 className="relative text-[20px] md:text-[25px] text-center text-white font-bold py-8 mt-10">
                      Claim bribe and unstake REKT voted?
                    </h1>
                  ) : (
                    <h1 className="relative text-[20px] md:text-[25px] text-center text-white font-bold py-8 mt-10">
                      Unstake REKT voted?
                    </h1>
                  )}
                </div>
                <div className="mt-5 relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                  <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                    <div className="py-2 pt-5 relative flex flex-row z-0 w-full">
                      <div className="relative hidden md:flex md:flex-row w-full h-max border-b-2 border-[#26f5d8]">
                        <p className="relative flex flex-col justify-left w-[150px] w-min-[130px] px-2 text-white text-[15px] font-bold">
                          Submission ID
                        </p>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Staked REKT
                        </h2>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Earnings ({tokenNative})
                        </h2>
                      </div>
                    </div>
                    <div className="relative z-0 w-full">
                      <div className="relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100">
                        <span className="flex justify-left items-center w-full md:w-[150px] md:w-min-[130px] px-2 py-1">
                          <span className="md:hidden text-white">
                            ID:&nbsp;
                          </span>
                          <p className="w-[150px] px-2 text-[16px] text-white font-bold uppercase">
                            {txnPopup.submissionId}
                          </p>
                        </span>
                        <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1">
                          <span className="md:hidden text-white">
                            Staked:&nbsp;
                          </span>
                          <p className="w-[180px] px-2 text-[16px] text-white font-bold ">
                            {txnPopup.totalRektVoted.toFixed(0)}
                          </p>
                        </span>
                        <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1">
                          <span className="md:hidden text-white">
                            Earnings:&nbsp;
                          </span>
                          <p className="w-[180px] px-2 text-[16px] text-white font-bold ">
                            {txnPopup.eligibleEarning.toFixed(0)}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row item-center justify-center">
                  <h1 className="text-white font-bold mt-8">
                    Eligible Claim: $ {""}
                    <span className="text-[#26f5d8] font-bold">
                      {txnPopup.eligibleEarning.toFixed(0)} {tokenNative}
                    </span>
                  </h1>
                </div>
                <div className="flex flex-row item-center justify-center">
                  <h1 className="text-white font-bold">
                    REKT to be unstaked:{" "}
                    <span className="text-[#26f5d8] font-bold">
                      {txnPopup.totalRektVoted.toFixed(0)}
                    </span>
                  </h1>
                </div>
                <div className="flex items-center justify-center py-8 space-x-12 mb-8">
                  <button
                    className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => props.closepopup(false)}
                  >
                    <p className="text-[16px] font-bold">Go back</p>
                  </button>
                  <button
                    className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => {
                      setConfirmPopup(true);
                      setCloseConfirmPopup(false);
                    }}
                  >
                    {txnPopup.eligibleEarning > 0 ? (
                      <label
                        className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer"
                        onClick={() => {
                          setConfirmPopup(true);
                          setCloseConfirmPopup(false);
                        }}
                      >
                        Claim
                      </label>
                    ) : (
                      <label
                        className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer"
                        onClick={() => {
                          setConfirmPopup(true);
                          setCloseConfirmPopup(false);
                        }}
                      >
                        Unstake
                      </label>
                    )}
                    <div className="z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]"></div>
                  </button>
                </div>
              </div>
            </div>
          </>
          {/* )} */}
        </>
      )}

      {confirmPopup && !closeConfirmPopup && (
        <div
          // fixed z-20 ml-[-1080.5px] mt-0
          className="fixed z-20 left-0 top-0 w-screen h-screen bg-[#110a26] flex items-center justify-center bg-opacity-80"
          onClick={() => {
            if (transactionSuccess !== null) {
              setCloseConfirmPopup(true);
              props.closepopup(false);
            } else {
              setCloseConfirmPopup(true);
            }
          }}
        >
          <div
            className="relative bg-[#110a26] bg-opacity-100 w-[440px] h-[250px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            {/* <button
              className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
              onClick={() => setCloseConfirmPopup(true)}
            >
              <p className="z-0 mt-[-2px] uppercase font-bold text-white">x</p>
            </button> */}

            <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
              <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                Confirm?
              </p>
            </div>
            <div className="flex items-center justify-center py-8 space-x-12 ">
              <button
                className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                onClick={() => setCloseConfirmPopup(true)}
              >
                <p className="text-[16px] font-bold">Go back</p>
              </button>
              <button
                className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                onClick={() => {
                  //   updateDB();
                  setGettingReceipt(true);
                  handleClaim();
                  // setCloseConfirmPopup(true);
                  // props.closepopup(false);
                }}
              >
                <label className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer">
                  Confirm
                </label>
                <div className="z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]"></div>
              </button>
            </div>
            {gettingReceipt ? (
              <>
                <LoadingLogo className="h-[50px] w-[50px] animate-spin"></LoadingLogo>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center">
                {transactionSuccess != null && transactionSuccess === false && (
                  <>
                    <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                      Transaction failed
                    </label>
                    <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                      {errorMessage}
                    </label>
                  </>
                )}
                {transactionSuccess != null && transactionSuccess === true && (
                  <label className="z-20 text-[#26f5d8] text-[16px] font-bold ">
                    Transaction success
                  </label>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default PopClaim;
