import { Link } from "react-router-dom";

import WojakGroup from "../public/images/NFT_Group.svg";
import one from "../public/images/1.svg";
import two from "../public/images/2.svg";
import three from "../public/images/3.svg";
import KingWojak from "../public/images/NFT_kingWojak.svg";

import DICE from "../public/images/NFT_exclusives_DICE.png";
import Unrektus from "../public/images/NFT_exclusives_Unrektus.png";
import Mojak from "../public/images/NFT_exclusives_LFG.png";

import Wojak1 from "../public/images/NFT_Wojak1.png";
import Wojak2 from "../public/images/NFT_Wojak2.png";
import Wojak3 from "../public/images/NFT_Wojak3.png";
import Wojak4 from "../public/images/NFT_Wojak4.png";
import Wojak5 from "../public/images/NFT_Wojak5.png";
import Wojak6 from "../public/images/NFT_Wojak6.png";
import Wojak7 from "../public/images/NFT_Wojak7.png";
import Wojak8 from "../public/images/NFT_Wojak8.png";
import Wojak9 from "../public/images/NFT_Wojak9.png";
import Wojak10 from "../public/images/NFT_Wojak10.png";
import Wojak11 from "../public/images/NFT_Wojak11.png";
import Wojak12 from "../public/images/NFT_Wojak12.png";

import Wojak13 from "../public/images/NFT_Wojak13.png";
import Wojak14 from "../public/images/NFT_Wojak14.png";
import Wojak15 from "../public/images/NFT_Wojak15.png";
import Wojak16 from "../public/images/NFT_Wojak16.png";
import Wojak17 from "../public/images/NFT_Wojak17.png";
import Wojak18 from "../public/images/NFT_Wojak18.png";
import Wojak19 from "../public/images/NFT_Wojak19.png";
import Wojak20 from "../public/images/NFT_Wojak20.png";
import Wojak21 from "../public/images/NFT_Wojak21.png";
import Wojak22 from "../public/images/NFT_Wojak22.png";
import Wojak23 from "../public/images/NFT_Wojak23.png";
import Wojak24 from "../public/images/NFT_Wojak24.png";

import Wojak25 from "../public/images/NFT_Wojak25.png";
import Wojak26 from "../public/images/NFT_Wojak26.png";
import Wojak27 from "../public/images/NFT_Wojak27.png";
import Wojak28 from "../public/images/NFT_Wojak28.png";
import Wojak29 from "../public/images/NFT_Wojak29.png";
import Wojak30 from "../public/images/NFT_Wojak30.png";
import Wojak31 from "../public/images/NFT_Wojak31.png";
import Wojak32 from "../public/images/NFT_Wojak32.png";
import Wojak33 from "../public/images/NFT_Wojak33.png";
import Wojak34 from "../public/images/NFT_Wojak34.png";
import Wojak35 from "../public/images/NFT_Wojak35.png";

const Nft = () => {
  return (
    <>
      <div className="mt-5 flex flex-col justify-center">
        {/* <div className="flex flex-row items-center justify-center space-x-8"> */}
        {/* <Wojak3 className='w-[800px]' /> */}

        {/* <Wojak1 /> */}
        {/* <img src={Wojak2} alt='Logo' /> */}
        {/* <Wojak37 /> */}
        <div className="flex flex-row justify-center ">
          <div className=" flex flex-col justify-center mb-[50px] ">
            <h1 className="text-[#23ff7b] text-center font-bold text-[30px] tracking-wider">
              Coming Soon
            </h1>
            <h1 className="text-white text-[15px] tracking-wider mt-[-10px]">
              To a marketplace near you, pleb
            </h1>
          </div>
        </div>
        <div className="mt-5 w-full flex justify-center">
          <div className="w-full absolute flex flex-col items-center justify-center">
            <div className="relative -z-1 flex flex-row flex-wrap items-center justify-center space-x-4 space-y-4">
              <img src={Wojak1} alt="Wojak1" />
              <img src={Wojak2} alt="Wojak2" />
              <img src={Wojak3} alt="Wojakx3" />
              <img src={Wojak4} alt="Wojak4" />
              <img src={Wojak5} alt="Wojak5" />
              <img src={Wojak6} alt="Wojak6" />
              <img src={Wojak7} alt="Wojak7" />
              <img src={Wojak8} alt="Wojak8" />
              <img src={Wojak9} alt="Wojak9" />
              <img src={Wojak10} alt="Wojak10" />
              <img src={Wojak11} alt="Wojak11" />
              <img src={Wojak12} alt="Wojak12" />
            </div>
            <div className="relative -z-1 flex flex-row flex-wrap items-center justify-center space-x-4 space-y-4">
              <img src={Wojak13} alt="Wojak13" />
              <img src={Wojak14} alt="Wojak14" />
              <img src={Wojak15} alt="Wojak15" />
              <img src={Wojak16} alt="Wojak16" />
              <img src={Wojak17} alt="Wojak17" />
              <img src={Wojak18} alt="Wojak18" />
              <img src={Wojak19} alt="Wojak19" />
              <img src={Wojak20} alt="Wojak20" />
              <img src={Wojak21} alt="Wojak21" />
              <img src={Wojak22} alt="Wojak22" />
              <img src={Wojak23} alt="Wojak23" />
              <img src={Wojak18} alt="Wojak18" />
              <img src={Wojak25} alt="Wojak25" />
            </div>
            <div className="relative -z-1 hidden xl:flex flex-row flex-wrap items-center justify-center space-x-4 space-y-4">
              <img src={Wojak26} alt="Wojak26" />
              <img src={Wojak27} alt="Wojak27" />
              <img src={Wojak28} alt="Wojak28" />
              <img src={Wojak29} alt="Wojak29" />
              <img src={Wojak30} alt="Wojak30" />
              <img src={Wojak31} alt="Wojak31" />
              <img src={Wojak32} alt="Wojak32" />
              <img src={Wojak33} alt="Wojak33" />
              <img src={Wojak34} alt="Wojak34" />
              <img src={Wojak35} alt="Wojak35" />
              <img src={Wojak16} alt="Wojak16" />
            </div>
          </div>
          <div className="w-full relative flex flex-col items-center justify-center mt-[130px] mb-[40px]">
            <div className="w-full md:w-4/5 z-1 bg-[#191236]/90 flex flex-col items-center justify-center">
              <div className="w-full px-2 flex flex-col md:flex-row items-center justify-center">
                <div className="flex items-center justify-center w-[300px] m-3">
                  <img src={KingWojak} alt="Logo" />
                </div>
                <p className="w-full flex flex-col md:float-right text-white text-[18px] text-justify tracking-wider px-5">
                  <span className="text-white text-center font-bold text-[30px] mt-5">
                    Cyber Wojakz<br></br>
                  </span>
                  A collection of 4,111 Wojakz NFTs. Another part of the Wojakz
                  ecosystem, these are your tickets to get into the race to
                  acquire the legendary item Unrektus. Collect, stake, earn,
                  play, access the NFT-gated story of the Wojakz, increased
                  voting power on Unrektus dApp, and more. <br></br>
                </p>
              </div>
              <div className="w-full flex flex-row flex-wrap items-center justify-evenly py-4">
                <button
                  onClick={() => {
                    window.open("https://t.me/WojakFinance_official");
                  }}
                  className="flex items-center justify-center bg-black border-[1px] border-white py-1 px-5 rounded-lg font-bold"
                >
                  <a
                    className="text-white"
                    href="https://t.me/WojakFinance_official"
                    target="_blank"
                  >
                    Join the pleb
                  </a>
                </button>
                <button className="flex items-center justify-center bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600  py-1 px-5 rounded-lg font-bold">
                  <a className="text-white">Mint - COMING SOON</a>
                </button>{" "}
              </div>
            </div>
            {/* mid page */}
            <div className="w-full z-1 flex flex-col md:flex-row items-center justify-center mt-[200px] md:mt-[100px] space-x-12">
              <div className="z-1 bg-[#191236]/80 flex flex-col items-center justify-center  w-[300px] h-[260px]">
                <div className="flex flex-row items-center justify-center">
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <div className=" h-[200px] w-[70px]">
                      <h1 className="top-0 ml-7 font-bold text-[50px] text-white  w-[40px]">
                        <img className="mt-4" src={one} alt="1" />
                      </h1>
                    </div>
                    <div className=" flex-1 mt-[-28px] space-y-5">
                      <h1 className="text-white text-[30px] font-bold">Mint</h1>
                      <p className="text-gray-400 text-[15px] tracking-wider">
                        Mint CyberWojakz NFTs and stake, earn, play, mint
                        exclusive NFTs.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="flex items-center justify-center bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600  py-1 px-5 rounded-lg font-bold">
                    <a className="text-white">Mint - Coming Soon</a>
                  </button>
                </div>
              </div>
              <div className="z-1 bg-[#191236]/80 flex flex-row items-center justify-center  w-[300px] h-[260px]">
                <div className="flew flew-col items-center justify-center">
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <div className=" h-[200px] w-[70px]">
                      <h1 className=" top-0 ml-7 font-bold text-[50px] text-white  w-[40px]">
                        <img className="mt-4" src={two} alt="2" />
                      </h1>
                    </div>
                    <div className=" flex-1 mt-[-28px] space-y-5">
                      <h1 className="text-white text-[30px] font-bold">
                        Stake
                      </h1>
                      <p className="text-gray-400 text-[15px] tracking-wider">
                        Stake your CyberWojakz NFTs and earn rewards paid in
                        $PLEB
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <button className="flex items-center justify-center bg-[#191236] border-[1px] border-[#d430b0] py-1 px-5 rounded-lg font-bold">
                      <a className="text-white font-normal">Coming Soon</a>
                    </button>
                  </div>
                </div>
              </div>
              <div className="z-1 bg-[#191236]/80 flex flex-row items-center justify-center  w-[300px] h-[260px]">
                <div className="flew flew-col items-center justify-center">
                  <div className="flex flex-row items-center justify-center space-x-2">
                    <div className=" h-[200px] w-[70px]">
                      <h1 className=" top-0 ml-7 font-bold text-[50px] text-white  w-[40px]">
                        <img className="mt-4" src={three} alt="3" />
                      </h1>
                    </div>
                    <div className=" flex-1 mt-[-28px] space-y-5">
                      <h1 className="text-white text-[28px] font-bold">Earn</h1>
                      <p className="text-gray-400 text-[15px] tracking-wider">
                        Earn $PLEB, unlock the story, get exclusive NFTs,
                        Unrektus vote & more.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-center">
                    <button className="flex items-center justify-center bg-[#191236] border-[1px] border-[#d430b0]  py-1 px-5 rounded-lg font-bold">
                      <a className="text-white font-normal">Coming Soon</a>
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>

            {/* Exclusives */}
            <div className="w-full relative space-y-10 z-1 bg-[#191236]/80 flex flex-col items-center justify-center  mt-[100px]">
              <div className=" flex flex-col items-center justify-center ">
                <h1 className="text-white text-[30px] font-bold">
                  Gamification
                </h1>
                <h1 className="text-[#26f5d8] text-[25px] font-bold">
                  Exclusive NFTs
                </h1>
              </div>

              <div className="w-full flex">
                <h1 className="text-white text-[18px] tracking-wider text-justify px-3">
                  Get access to the story-game by staking your Cyberwojakz NFTs
                  from the classic collection, yield $PLEB. Above a certain
                  threshold of $PLEB holdings, you get access to the game. Play
                  the game by following the adventures of CJ & Derpina in their
                  quest to capture the Unrektus, a mysterious ancient artifact
                  that grants its owner extreme luck on the markets, never get
                  REKT ever again by holding Unrektus! The story is split in 10
                  parts, validate each part by finding the right password hidden
                  in the story and mint an Exclusive NFT per part. Disclaimer:
                  The number of Exclusive NFTs is limited, scarcity increases as
                  per the game progresses. Will you be of the rare ones to get
                  an Unrektus?
                </h1>
              </div>
              <div className="flex flex-col md:flex-row items-center justify-center px-4">
                <div className=" flex ">
                  <img src={DICE} alt="Wojak9" />
                </div>
                <div className=" flex ">
                  <img src={Unrektus} alt="Wojak9" />
                </div>
                <div className=" flex  ">
                  <img src={Mojak} alt="Wojak9" />
                </div>
              </div>
            </div>

            {/* bottom page */}
            <div className="w-full relative z-1 bg-[#191236]/80 flex flex-row items-center justify-center  mt-[100px]">
              <div className="flex flex-col items-center justify-center">
                <img className="" src={WojakGroup} alt="WojakGroup" />
                <div className="flex flex-col items-center justify-center top-14">
                  <h1 className="mt-5 relative text-[#d430b0] text-center text-xl md:text-2xl font-bold ">
                    Start in seconds with the Unrektus dApp
                  </h1>
                  <h1 className="relative text-white text-[20px] text-center">
                    Connect your wallet and start getting Unrekt!
                  </h1>
                  <Link
                    to="/"
                    className="mt-[40px] flex items-center justify-center bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600  py-1 px-5 rounded-lg"
                  >
                    <label className="text-white text-xl md:text-2xl uppercase">
                      Discover how REKT you got
                    </label>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nft;
