import React from "react";
import { UseContractInfo } from "../web3/ContractContext";

export default function VoteInfoHeader(props) {
  const { sessionEnded, tokenNative } = UseContractInfo();
  return (
    <div className="relative z-0 w-full">
      <div className="relative hidden md:flex justify-start ">
        {/* <span className="flex flex-row w-full px-1"> */}
        <div
          className="flex flex-col px-2 py-2 w-fit md:w-[60px] md:min-w-[40px]"
          //   onClick={() => setSortField([1, sortedName, 0])}
        >
          <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
            ID
          </p>

          {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo> */}
        </div>
        <div
          className="flex flex-col px-2 py-2 w-fit md:w-[150px] md:min-w-[120px]"
          //   onClick={() => setSortField([12, sortedREKTp, 1])}
        >
          <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
            Rekt ({tokenNative})
          </p>
          {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo> */}
        </div>
        <div
          className="flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]"
          //   onClick={() => setSortField([13, sortedREKTb, 2])}
        >
          <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
            Bribe ({tokenNative})
          </p>
          {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
        </div>
        <div
          className="flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]"
          //   onClick={() => setSortField([13, sortedREKTb, 2])}
        >
          <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
            Votes
          </p>
          {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
        </div>
        {/* </span> */}
        <div className="flex px-2 w-full md:w-[200px] md:min-w-[180px] justify-center md:justify-start items-center">
          <p className="text-[14px] text-center text-white font-bold uppercase hover:text-gray-200">
            Transactions
          </p>
        </div>
        {/* </span> */}
        {/* <span className="flex flex-row w-full"> */}
        <div
          className="flex flex-col justify-center px-2 py-2 md:w-[120px] md:min-w-[100px]"
          //   onClick={() => setSortField([13, sortedREKTb, 2])}
        >
          <p className="text-center text-[14px] text-white font-bold uppercase hover:text-gray-200">
            Boost
          </p>
          {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
        </div>
        {/* <div
            className="flex flex-col justify-center px-2 py-2 w-[120px] min-w-[100px]"
            //   onClick={() => setSortField([13, sortedREKTb, 2])}
          >
            <p className="text-center text-[14px] text-white font-bold uppercase hover:text-gray-200">
              Actions
            </p>
          </div>
        </span> */}
      </div>
    </div>
  );
}
