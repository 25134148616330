import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as TwitterlogoW } from "../public/images/twitterlogwhite.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import { UseContractInfo } from "../web3/ContractContext";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { TextField } from "@mui/material";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";

function PopWinPot(props) {
  const { sessionEnded, tokenNative } = UseContractInfo();

  const [isOpen, setIsOpen] = useState(props.openPopup);
  const txnPopup = props.txnPopup;

  const [winPotVal, setWinPotVal] = useState(0);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [closeConfirmPopup, setCloseConfirmPopup] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [, updateState] = useState();
  const forceRender = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (closeConfirmPopup) {
      setTransactionSuccess(null);
    }
  }, [closeConfirmPopup]);

  function handleClick(value) {
    const amount = value.target.textContent;
    setWinPotVal(amount);
    // console.log(amount);
  }

  async function handleSendPot() {
    const potValue = winPotVal;
    const { status, message } = await props.handleSendPot(potValue);
    setTransactionSuccess(status);
    setGettingReceipt(false);
    if (status === true) {
      props.success(true);
    } else {
      setErrorMessage(message);
    }
  }

  return (
    <>
      {isOpen && (
        <>
          {/* {!sessionEnded && ( */}
          <>
            <div
              // fixed z-20 ml-[-1080.5px] mt-0
              className="fixed z-20 left-0 top-[-20px] w-screen h-screen bg-[#110a26]/80 flex items-center justify-center"
              onClick={() => props.closepopup(false)}
            >
              <div
                className="relative bg-[#110a26] w-[900px] h-[450px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
                onClick={(e) => {
                  // do not close modal if anything inside modal content is clicked
                  e.stopPropagation();
                }}
              >
                <div className="relative w-full border-b-[1px] border-b-gray-400 flex items-center justify-center">
                  <h1 className="relative text-[25px] text-white font-bold py-8 mt-10">
                    How much prize value you want to send?
                  </h1>
                </div>
                {/* <div className="z-0 mt-[-95px] absolute rounded-lg h-[120px] w-[880px] border-4 blur border-[#26f5d8]"></div> */}
                <div>
                  <div className="mt-8 flex flex-row items-center justify-center space-x-5">
                    <h1 className="text-[#26f5d8]  border-white">
                      Choose winning pot amount:
                    </h1>
                    <TextField
                      sx={{ input: { color: "white" } }}
                      color="secondary"
                      focused
                      type="number"
                      min={0}
                      placeholder="Wining Pot Value"
                      value={winPotVal}
                      onChange={(event) => setWinPotVal(event.target.value)}
                    />
                  </div>
                  <div className="mt-5 flex flex-row items-center justify-center space-x-5">
                    <h1 className="text-white font-bold">Send Amount$</h1>
                    <h1 className="text-[#26f5d8] font-bold">
                      {winPotVal} {tokenNative}
                    </h1>
                  </div>
                </div>
                <div className="flex items-center justify-center py-8 space-x-12 mb-8">
                  <button
                    className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => props.closepopup(false)}
                  >
                    <p className="text-[16px] font-bold">Go back</p>
                  </button>
                  <button
                    className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => {
                      setConfirmPopup(true);
                      setCloseConfirmPopup(false);
                    }}
                  >
                    <label
                      className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer"
                      onClick={() => {
                        setConfirmPopup(true);
                        setCloseConfirmPopup(false);
                      }}
                    >
                      Send
                    </label>
                    <div className="z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]"></div>
                  </button>
                </div>
              </div>
            </div>
          </>
          {/* )} */}
        </>
      )}

      {confirmPopup && !closeConfirmPopup && (
        <div
          // fixed z-20 ml-[-1080.5px] mt-0
          className="fixed z-20 left-0 top-0 w-screen h-screen bg-[#110a26] flex items-center justify-center bg-opacity-80"
          onClick={() => {
            if (transactionSuccess !== null) {
              setCloseConfirmPopup(true);
              props.closepopup(false);
            } else {
              setCloseConfirmPopup(true);
            }
          }}
        >
          <div
            className="relative bg-[#110a26] bg-opacity-100 w-[440px] h-[250px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]"
            onClick={(e) => {
              // do not close modal if anything inside modal content is clicked
              e.stopPropagation();
            }}
          >
            {winPotVal > 0 ? (
              <>
                <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
                  <p className="relative text-[25px] text-white font-bold py-2 mt-2">
                    Confirm your send amount:&nbsp;$
                  </p>
                  <p className="relative text-[25px] text-[#26f5d8] font-bold py-2 mt-2">
                    {winPotVal} {tokenNative}
                  </p>
                </div>
                <div className="flex items-center justify-center py-8 space-x-12 ">
                  <button
                    className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => setCloseConfirmPopup(true)}
                  >
                    <p className="text-[16px] font-bold">Go back</p>
                  </button>
                  <button
                    className="text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => {
                      //   updateDB();
                      setGettingReceipt(true);
                      handleSendPot();
                      // setCloseConfirmPopup(true);
                      // props.closepopup(false);
                    }}
                  >
                    <label className="z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer">
                      Confirm
                    </label>
                    <div className="z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]"></div>
                  </button>
                </div>
                {gettingReceipt ? (
                  <>
                    <LoadingLogo className="h-[50px] w-[50px] animate-spin"></LoadingLogo>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    {transactionSuccess != null &&
                      transactionSuccess === false && (
                        <>
                          <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                            Transaction failed
                          </label>
                          <label className="z-20 text-[#d430b0] text-[16px] font-bold">
                            {errorMessage}
                          </label>
                        </>
                      )}
                    {transactionSuccess != null &&
                      transactionSuccess === true && (
                        <label className="z-20 text-[#26f5d8] text-[16px] font-bold ">
                          Transaction success
                        </label>
                      )}
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center">
                  <p className="relative text-[25px] text-white font-bold py-8 mt-10">
                    Input a prize value amount!
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center py-8 space-x-12 mb-8">
                  <button
                    className="text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center"
                    onClick={() => {
                      if (transactionSuccess !== null) {
                        setCloseConfirmPopup(true);
                        props.closepopup(false);
                      } else {
                        setCloseConfirmPopup(true);
                      }
                    }}
                  >
                    <p className="text-[16px] font-bold">Go back</p>
                  </button>{" "}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default PopWinPot;
