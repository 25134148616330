import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as TwitterlogoW } from "../public/images/Xlogo.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import VoteTxnDiv from "./votetxndiv";
import VoteInfoHeader from "./voteInfoHeader";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

import dclogo from "../public/images/dclogo.png";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { TextField } from "@mui/material";

function PopBribe(props) {
	const { sessionEnded, tokenNative, bribeTax } = UseContractInfo();

	const [isOpen, setIsOpen] = useState(props.openPopup);
	const txnPopup = props.txnPopup;

	const [bribeVal, setBribeVal] = useState(0);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [closeConfirmPopup, setCloseConfirmPopup] = useState(false);
	const [transactionSuccess, setTransactionSuccess] = useState(null);
	const [gettingReceipt, setGettingReceipt] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [, updateState] = useState();
	const forceRender = useCallback(() => updateState({}), []);

	useEffect(() => {
		if (closeConfirmPopup) {
			setTransactionSuccess(null);
			setErrorMessage("");
		}
	}, [closeConfirmPopup]);

	function handleClick(value) {
		const amount = value.target.textContent;
		setBribeVal(amount);
		// console.log(amount);
	}

	async function handleBribe() {
		var bribeArray = {
			bribeAmount: bribeVal,
			submissionId: txnPopup.submissionId
		};
		const { status, message } = await props.handleBribe(bribeArray);
		setTransactionSuccess(status);
		setGettingReceipt(false);
		if (status === true) {
			props.success(true);
		} else {
			setErrorMessage(message);
		}
	}

	return (
		<>
			{isOpen && (
				<>
					{sessionEnded && (
						<>
							<div
								// fixed z-20 ml-[-1080.5px] mt-0
								className='fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80'
								onClick={(e) => {
									props.closepopup(false);
								}}
							>
								<div
									className='relative bg-[#110a26] bg-opacity-100 w-full md:w-[900px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]'
									onClick={(e) => {
										// do not close modal if anything inside modal content is clicked
										e.stopPropagation();
									}}
								>
									<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-col items-center justify-center'>
										<p className='relative text-[25px] text-white font-bold py-2 mt-2'>
											Session has ended
										</p>
									</div>
									<div className='flex items-center justify-center py-8 space-x-12'>
										<button
											className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
											onClick={() => props.closepopup(false)}
										>
											<p className='text-[16px] font-bold'>Go back</p>
										</button>
									</div>
								</div>
							</div>
						</>
					)}
					{!sessionEnded && (
						<>
							<div
								className='fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80'
								onClick={() => props.closepopup(false)}
							>
								<div
									className='relative bg-[#110a26] bg-opacity-100 w-full md:w-[900px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#ff31e9]'
									onClick={(e) => {
										// do not close modal if anything inside modal content is clicked
										e.stopPropagation();
									}}
								>
									<button
										className='z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4'
										onClick={() => props.closepopup(false)}
									>
										<p className='z-0 mt-[-2px] uppercase font-bold text-white'>
											x
										</p>
									</button>
									<div className='relative w-full border-b-[1px] border-b-gray-400 flex items-center justify-center'>
										<h1 className='relative text-[22px] md:text-[25px] text-center text-white font-bold py-8 mt-10'>
											How much do you want to bribe for this REKT transaction?
										</h1>
									</div>
									<div className='z-30 border-t-[2px] border-b-[2px] mt-2 border-[#26f5d8] rounded-xl max-content flex flex-col items-center justify-center'>
										<VoteInfoHeader></VoteInfoHeader>
										<div className='relative z-0 w-full'>
											<div className='pb-5 flex flex-wrap justify-start bg-[#191236] rounded-xl'>
												<VoteTxnDiv votetxndiv={txnPopup} />
												<span className='flex justify-center px-2 py-2 w-[120px] min-w-[100px]'>
													<p className='text-[16px] text-white font-bold '>
														<span className='md:hidden text-white'>
															Boost:{" "}
														</span>
														{txnPopup.boostX}x
													</p>
												</span>
											</div>
										</div>
									</div>
									<div className='relative w-full px-4'>
										<h1 className='text-[#26f5d8] text-center border-white mt-8'>
											Choose bribe amount:
										</h1>
										<div className='flex flex-row flex-wrap items-center justify-center space-x-5'>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													"& > *": {
														m: 1
													}
												}}
											>
												<ButtonGroup
													className='flew flew-col items-center justify-center'
													size='large'
													aria-label='large button group'
												>
													<Button key='one' onClick={handleClick}>
														10
													</Button>
													,
													<Button onClick={handleClick} key='two'>
														20
													</Button>
													,
													<Button onClick={handleClick} key='three'>
														50
													</Button>
													,
													<Button onClick={handleClick} key='four'>
														100
													</Button>
													,
													<Button onClick={handleClick} key='five'>
														200
													</Button>
													,
													<Button onClick={handleClick} key='six'>
														500
													</Button>{" "}
												</ButtonGroup>
											</Box>
											<h1 className='text-white font-bold'>Bribe for $</h1>
											<TextField
												sx={{
													"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
														{
															display: "none"
														},
													"& input[type=number]": {
														MozAppearance: "textfield"
													},
													input: { color: "white" }
												}}
												color='secondary'
												focused
												type='number'
												min={0}
												value={bribeVal}
												onChange={(event) => setBribeVal(event.target.value)}
											/>
											<h1 className='text-[#26f5d8] font-bold'>
												{(
													Number(bribeVal) -
													Number(bribeVal) * (bribeTax / 100)
												).toFixed(2)}{" "}
												{tokenNative}
											</h1>
										</div>
										<div className='flex'>
											{bribeTax} % of bribe is collected as tax
										</div>
										<h1 className='text-white text-center border-white'>
											New Total Bribe:
											<span className='text-[#26f5d8]'>
												{(
													Number(txnPopup.totalBribe) +
													(Number(bribeVal) -
														Number(bribeVal) * (bribeTax / 100))
												).toFixed(2)}{" "}
												{tokenNative}
											</span>
										</h1>
									</div>
									<div className='mt-5 relative w-full flex flex-wrap items-center justify-center py-8 mb-8'>
										<span className='mb-5 flex w-full items-center justify-between px-4 md:px-14'>
											<button
												className='text-white bg-[#110a26] border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => props.closepopup(false)}
											>
												<p className='text-[16px] font-bold'>Go back</p>
											</button>
											<button
												className='text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => {
													setConfirmPopup(true);
													setCloseConfirmPopup(false);
												}}
											>
												<p className='text-[16px] font-bold animate-pulse'>
													Bribe
												</p>
											</button>
										</span>
										<span className='mb-5 flex w-full items-center justify-center'>
											<div className='flex flex-row items-center justify-center px-5 space-x-14'>
												<button
													className='flex flex-row absolute x-space-2 items-center justify-center'
													onClick={() =>
														window.open(
															`https://twitter.com/intent/tweet?text=I%20am%20bribing%20${bribeVal}%20on%20my%20REKT%20submission%20where%20I%20got%20REKT%20by%20${txnPopup.amountRekt}%20${tokenNative}
                              %0A%0ALet's%20get%20UNREKT%0A%0ABribe%20is%20for%20you%20to%20claim%20if%20you%20vote%21%20%0A%0AOnly%20at%20unrektus.fi`
														)
													}
												>
													{" "}
													<TwitterlogoW className='absolute h-4 w-4 hover:cursor-pointer'></TwitterlogoW>
												</button>
											</div>
											<div className='flex flex-row items-center justify-center px-5 space-x-14'>
												<button
													className=' flex flex-row absolute x-space-2 items-center justify-center'
													onClick={() =>
														window.open(
															`https://twitter.com/intent/tweet?text=I%20am%20bribing%20${bribeVal}%20on%20my%20REKT%20submission%20where%20I%20got%20REKT%20by%20${txnPopup.amountRekt}%20${tokenNative}
                              %0A%0ALet's%20get%20UNREKT%0A%0ABribe%20is%20for%20you%20to%20claim%20if%20you%20vote%21%20%0A%0AOnly%20at%20unrektus.fi`
														)
													}
												>
													{" "}
													<TglogoW className='absolute h-4 w-4 hover:cursor-pointer'></TglogoW>
												</button>
											</div>
										</span>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}

			{confirmPopup && !closeConfirmPopup && (
				<div
					// fixed z-20 ml-[-1080.5px] mt-0
					className='fixed z-20 left-0 top-0 w-screen h-screen bg-[#110a26] flex items-center justify-center bg-opacity-80'
					onClick={() => {
						if (transactionSuccess !== null) {
							setCloseConfirmPopup(true);
							props.closepopup(false);
						} else {
							setCloseConfirmPopup(true);
						}
					}}
				>
					<div
						className='relative bg-[#110a26] bg-opacity-100 w-[440px] h-[250px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]'
						onClick={(e) => {
							// do not close modal if anything inside modal content is clicked
							e.stopPropagation();
						}}
					>
						{/* <button
              className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
              onClick={() => setCloseConfirmPopup(true)}
            >
              <p className="z-0 mt-[-2px] uppercase font-bold text-white">x</p>
            </button> */}
						{bribeVal > 0 ? (
							<>
								<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-col text-center items-center justify-center'>
									<div className='flex flex-row'>
										<p className='relative text-[20px] text-white font-bold py-2 mt-2'>
											Confirm your bribe:&nbsp;$
										</p>
										<p className='relative text-[20px] text-[#26f5d8] font-bold py-2 mt-2'>
											{(
												Number(bribeVal) -
												Number(bribeVal) * (bribeTax / 100)
											).toFixed(2)}{" "}
											{tokenNative}
										</p>
									</div>
									<h1 className='text-white text-center border-white'>
										New Total Bribe:{" "}
										<span className='text-[#26f5d8]'>
											{(
												Number(txnPopup.totalBribe) +
												(Number(bribeVal) - Number(bribeVal) * (bribeTax / 100))
											).toFixed(2)}{" "}
											{tokenNative}
										</span>
									</h1>
								</div>
								<div className='flex items-center justify-center py-8 space-x-12 '>
									<button
										className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
										onClick={() => setCloseConfirmPopup(true)}
									>
										<p className='text-[16px] font-bold'>Go back</p>
									</button>
									<button
										className='text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
										onClick={() => {
											//   updateDB();
											setGettingReceipt(true);
											handleBribe();
											// setCloseConfirmPopup(true);
											// props.closepopup(false);
										}}
									>
										<label className='z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer'>
											Confirm
										</label>
										<div className='z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]'></div>
									</button>
								</div>
								{gettingReceipt ? (
									<>
										<LoadingLogo className='h-[50px] w-[50px] animate-spin'></LoadingLogo>
									</>
								) : (
									<div className='mt-5 flex flex-col items-center justify-center'>
										{transactionSuccess != null &&
											transactionSuccess === false && (
												<>
													<label className='z-20 mt-[-25px] mb-[10px] text-[#d430b0] text-[16px] font-bold'>
														Transaction failed
													</label>
													<label className='z-20 mt-[-15px] mb-[25px] text-[#d430b0] text-[16px] font-bold'>
														{errorMessage}
													</label>
												</>
											)}
										{transactionSuccess != null &&
											transactionSuccess === true && (
												<label className='z-20 mt-[-25px] mb-[10px] text-[#26f5d8] text-[16px] font-bold '>
													Transaction success
												</label>
											)}
									</div>
								)}
							</>
						) : (
							<>
								<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center'>
									<p className='relative text-[25px] text-white font-bold py-8 mt-10'>
										Choose a correct bribe amount
									</p>
								</div>
								<div className='flex flex-col items-center justify-center py-8 space-x-12 mb-8'>
									<button
										className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
										onClick={() => {
											if (transactionSuccess !== null) {
												setCloseConfirmPopup(true);
												props.closepopup(false);
											} else {
												setCloseConfirmPopup(true);
											}
										}}
									>
										<p className='text-[16px] font-bold'>Go back</p>
									</button>{" "}
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
}
export default PopBribe;
