import React, { useState, useEffect, useCallback } from "react";
import { UseContractInfo } from "../web3/ContractContext";

export function IncreaseSession() {
  const {
    checkStatus,
    currentStatus,
    winnerPicked,
    unrektusSession,
    sessionBreak,
    sessionEnded,
    handleIncreaseSession,
  } = UseContractInfo();

  const [transactionIsSuccess, setTransactionIsSuccess] = useState(false);

  const handleSession = async () => {
    // console.log("IncreaseSession");
    const status = await handleIncreaseSession();
    if (status) {
      setTransactionIsSuccess(true);
    }
  };

  useEffect(() => {
    if (transactionIsSuccess === true) {
      checkStatus();
    }
  }, [transactionIsSuccess]);

  return (
    <>
      {sessionEnded && !sessionBreak && (
        <>
          {currentStatus &&
            Number(currentStatus._currentHeighestVotes) === 0 && (
              <button
                className="px-2 text-[15px] z-20 text-[#ff4940]"
                onClick={handleSession}
              >
                Increase Session ID?
              </button>
            )}
        </>
      )}
    </>
  );
}
export default IncreaseSession;
