import { useState, useEffect, useCallback, updateState } from "react";
import { useNavigate } from "react-router-dom";

// import RektVoteDiv from "../components/RektVoteDiv";
import PopClaim from "./popClaim";
import PopWithdraw from "./popWithdraw";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";
import LoadingSkeleton from "../components/loadingSkeleton";

export default function Portfolio(props) {
  const navigate = useNavigate();
  const {
    connected,
    currAddress,
    currChainId,
    wrongNetwork,
    comingSoon,
    currProvider,
    currSigner,
  } = UseInfoUser();
  const {
    initiated,
    currentStatus,
    unrektusSession,
    sessionEnded,
    tokenNative,
    handleClaimBribe,
    handleWithdrawBribe,
  } = UseContractInfo();
  const {
    unRektness,
    userPastVotes,
    getUserPastVotes,
    getRektPower,
    userPastSubmission,
    getUserPastSubmissions,
  } = UseUserPortfolio();

  const [showPortfolio, setShowPortfolio] = useState(true);

  const [selectedTxn, setSelectedTxn] = useState(null);
  const [transactionIsSuccess, setTransactionIsSuccess] = useState(null);

  const [claimBribevar, setClaimBribevar] = useState(false);
  const [withdrawBribevar, setWithdrawBribevar] = useState(false);

  const [isLoadingVotes, setIsLoadingVotes] = useState(true);
  const [isLoadingSubs, setIsLoadingSubs] = useState(true);
  const [pastVoteDiv, setPastVoteDiv] = useState([]);
  const [pastSubsDiv, setPastSubsDiv] = useState([]);

  // async function getVotingHistory() {
  //   if (userPastVotes && userPastVotes.length > 0) {
  //     var array = await getUserVotesDetails(userPastVotes);
  //   }
  //   return array;
  // }
  const handleClick = (type, selected) => {
    if (type === "claim") {
      setClaimBribevar(true);
      setSelectedTxn(selected);
      setTransactionIsSuccess(null);
    } else if (type === "withdraw") {
      setWithdrawBribevar(true);
      setSelectedTxn(selected);
      setTransactionIsSuccess(null);
    }
  };

  function getVotedDivs(votingHistory) {
    // console.log(votingHistory);
    // console.log("get past Vote Divs");
    var divs = [];
    if (votingHistory.length > 0) {
      // var arr = userPastVotes.forEach((vote) => {
      var arr = votingHistory.forEach((vote) => {
        const totalRektVoted = vote.totalRektVoted;
        const bribeClaimed = vote.bribeClaimed;
        const eligibleEarning = vote.eligibleEarning;
        const rektStaked = vote.totalRektVoted;
        const rektUnstaked = vote.rektUnstaked;
        const voteSession = vote.sessionId;
        // console.log(vote);
        // if (bribeClaimed === false) {
        divs.push(
          <>
            <div
              className="relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
              key={vote.submissionId}
            >
              <span className="flex justify-left items-center w-[150px] w-min-[130px] px-2 py-1">
                <span className="md:hidden text-white">ID:&nbsp;</span>
                <p className="text-[16px] text-white font-bold uppercase">
                  {vote.submissionId}
                </p>
              </span>
              <span className="flex flex-row justify-left items-center w-full md:w-[360px] md:w-min-[300px]">
                <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1 ">
                  <span className="md:hidden text-white">Staked:&nbsp;</span>
                  <p className="text-[16px] text-white font-bold ">
                    {totalRektVoted.toFixed(0)}
                  </p>
                </span>
                <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1 ">
                  <span className="md:hidden text-white">
                    Earnings ({tokenNative}) &nbsp;
                  </span>
                  <p className="text-[16px] text-white font-bold ">
                    {eligibleEarning.toFixed(2)}
                  </p>
                </span>
              </span>
              <span className="px-2 py-1 mt-3 md:mt-0 flex justify-center items-center w-full md:w-[220px] md:w-min-[200px] text-center">
                {eligibleEarning > 0 ? (
                  <>
                    {bribeClaimed === false ? (
                      <>
                        {voteSession < unrektusSession ? (
                          <button
                            className="text-white border-[1px] py-[2px] px-[20px] rounded-lg border-[#26f5d8]"
                            onClick={() => {
                              handleClick("claim", vote);
                            }}
                          >
                            <p className="text-[14px] font-bold">
                              Claim & Unstake
                            </p>
                          </button>
                        ) : (
                          <button className="text-gray-500 border-[1px] py-[2px] px-[20px] rounded-lg border-gray-500">
                            <p className="text-[14px] font-bold">
                              Claim & Unstake
                            </p>
                          </button>
                        )}
                      </>
                    ) : (
                      <label className="text-[#26f5d8] ml-1 animate-pulse">
                        Claimed
                      </label>
                    )}
                  </>
                ) : (
                  <>
                    {!rektUnstaked ? (
                      <>
                        {voteSession < unrektusSession ? (
                          <button
                            className="text-white border-[1px] py-[2px] px-[20px] rounded-lg border-[#d430b0]"
                            onClick={() => {
                              handleClick("claim", vote);
                            }}
                          >
                            <p className="text-[14px] font-bold">
                              Unstake REKT
                            </p>
                          </button>
                        ) : (
                          <button className="text-gray-500 border-[1px] py-[2px] px-[20px] rounded-lg border-[#d430b0]">
                            <p className="text-[14px] text-gray-400 font-bold">
                              Unstake REKT
                            </p>
                          </button>
                        )}
                      </>
                    ) : (
                      <button className="text-[#26f5d8] ml-1 cursor-default animate-pulse">
                        Unstaked
                      </button>
                    )}
                  </>
                )}
              </span>
            </div>
          </>
        );
        // }
      });
      setPastVoteDiv(divs);
      // var voted = false;
      // console.log(ethers.BigNumber.from(submission.submissionId));
      // if (
      //   pastVotesUser.length > 0 &&
      //   pastVotesUser.includes(
      //     ethers.BigNumber.from(submission.submissionId)
      //   )
      // ) {
      //   console.log("alreadyVoted");
      //   voted = true;
      // }
      // });
    }
  }
  function getSubmittedDivs(userInfo) {
    // console.log("get past Submission Divs");
    var divs = [];
    const submissionHistory = userInfo.pastSubmissionInfo;
    if (submissionHistory.length > 0) {
      // var arr = userPastVotes.forEach((vote) => {
      var arr = submissionHistory.forEach((sub) => {
        const votesReceived = sub.totalVotes;
        const bribe = sub.totalBribe;
        // console.log(sub);
        const bribeWithdrew = sub.bribeWithdrew;
        const submissionSession = sub.sessionId;
        // console.log(bribeWithdrew);

        var bribeSet = false;
        if (bribe > 0) {
          bribeSet = true;
        }
        var canWithdraw = true;
        if (votesReceived > 0) {
          canWithdraw = false;
        }

        if (bribeSet && canWithdraw) {
          // console.log(sub);
          divs.push(
            <div
              className="relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
              key={sub.submissionId}
            >
              <span className="flex justify-left items-center w-[150px] w-min-[130px] px-2 py-1">
                <span className="md:hidden text-white">ID:&nbsp;</span>
                <p className="text-[16px] text-white font-bold uppercase">
                  {sub.submissionId}
                </p>
              </span>
              <span className="flex flex-row justify-left items-center w-full md:w-[360px] md:w-min-[300px]">
                <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1 ">
                  <span className="md:hidden text-white">Votes:&nbsp;</span>
                  <p className="text-[16px] text-white font-bold ">
                    {votesReceived.toFixed(0)}
                  </p>
                </span>
                <span className="flex justify-left items-center w-[180px] w-min-[150px] px-2 py-1 ">
                  <span className="md:hidden text-white">Bribe&nbsp;</span>
                  <p className="text-[16px] text-white font-bold ">
                    {bribe.toFixed(2)}
                  </p>
                </span>
              </span>
              <span className="px-2 py-1 mt-3 md:mt-0 flex justify-center items-center w-full md:w-[220px] md:w-min-[200px] text-center">
                {submissionSession < unrektusSession ? (
                  <>
                    {bribeWithdrew ? (
                      <label className="text-[#26f5d8] ml-1 animate-pulse">
                        Withdrew
                      </label>
                    ) : (
                      <button
                        className="text-white border-[1px] py-[2px] px-[20px] rounded-lg border-[#26f5d8]"
                        onClick={() => {
                          handleClick("withdraw", sub);
                        }}
                      >
                        <p className="text-[14px] font-bold">Withdraw Bribe</p>
                      </button>
                    )}
                  </>
                ) : (
                  <button className="text-gray-500 border-[1px] py-[2px] px-[20px] rounded-lg border-gray-500">
                    <p className="text-[14px] font-bold">Withdraw Bribe</p>
                  </button>
                )}
              </span>
            </div>
          );
        }
        // }
      });
      setPastSubsDiv(divs);
    }
  }
  useEffect(() => {
    if (userPastSubmission != null) {
      const getData = async () => {
        setPastSubsDiv([]);
        // console.log(userPastSubmission);
        await getSubmittedDivs(userPastSubmission);
        setIsLoadingSubs(false);
      };
      getData();
    }
  }, [userPastSubmission]);

  useEffect(() => {
    if (userPastVotes != null) {
      const getData = async () => {
        setPastVoteDiv([]);
        await getVotedDivs(userPastVotes);
        setIsLoadingVotes(false);
      };
      getData();
    }
  }, [userPastVotes]);

  useEffect(() => {
    if (transactionIsSuccess === true) {
      const getData = async () => {
        if (claimBribevar === true) {
          getRektPower();
          const { userCurrentVotes, userPastVotes } = await getUserPastVotes();
        } else {
          const { userCurrentSubmission, userPastSubmission } =
            await getUserPastSubmissions();
        }
      };
      getData();
    }
  }, [transactionIsSuccess]);

  function disableScroll() {
    if (claimBribevar) {
      document.body.style.overflow = "hidden";
    }
  }

  function enableScroll() {
    if (!claimBribevar) {
      document.body.style.overflow = "initial";
    }
  }

  return (
    <>
      <div className="mt-5 px-3 py-3 w-full flex flex-col items-center justify-center">
        {/* {!REKTdata && (
          <div className="py-2 flex flex-row items-center justify-center">
            <h1 className="text-[#ff31e9] text-[18px] font-bold animate-pulse">
              <a href="/">Discover your Unrektus potential?</a>
            </h1>
          </div>
        )} */}

        {/* <div className="flex flex-row items-center justify-center h-[40px] mt-[-40px]">
              <h1 className="text-white text-[30px]">Average gains: &nbsp;</h1>
              {avgGain >= 100 && (
                <h1 className="text-green-600 text-[30px]">
                  {(avgGain - 100).toFixed(2)}%
                </h1>
              )}
              {avgGain < 100 && (
                <h1 className="text-red-600 text-[30px]">
                  -{avgGain.toFixed(2)}%
                </h1>
              )}
            </div> */}
        {/* {!comingSoon && ( */}
        {/* <> */}
        {claimBribevar && (
          <PopClaim
            closepopup={setClaimBribevar}
            openPopup={claimBribevar}
            txnPopup={selectedTxn}
            handleClaim={handleClaimBribe}
            success={setTransactionIsSuccess}
          >
            {/* {console.log(rektdivVotevar)} */}
            {disableScroll()}
          </PopClaim>
        )}
        {!claimBribevar && enableScroll()}
        {withdrawBribevar && (
          <PopWithdraw
            closepopup={setWithdrawBribevar}
            openPopup={withdrawBribevar}
            txnPopup={selectedTxn}
            handleWithdraw={handleWithdrawBribe}
            success={setTransactionIsSuccess}
          >
            {/* {console.log(rektdivVotevar)} */}
            {disableScroll()}
          </PopWithdraw>
        )}
        {!withdrawBribevar && enableScroll()}

        {/* Voted */}
        <div className="flex flex-col items-center pb-2 mt-10 mb-2 justify-center">
          <div className="relative mt-2 px-1 flex-col items-center items-center overflow-x-hidden max-h-[500px]">
            <div className="pt-4 mt-2 flex flex-col md:flex-row items-center justify-center">
              <h1 className="text-[#26f5d8] text-[25px] uppercase font-extrabold tracking-wider">
                Unstake&nbsp;
                <span className="text-[#ff31e9]">$uREKT&nbsp;</span>& &nbsp;
              </h1>
              <h1 className="text-[#26f5d8] text-[25px] uppercase font-extrabold tracking-wider">
                Claim&nbsp;
                <span className="text-[#ff31e9]">BRIBES&nbsp;</span>
              </h1>
            </div>
            {pastVoteDiv.length > 0 ? (
              <>
                <div className="mt-5 relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                  <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                    <div className="py-2 pt-5 relative flex flex-row z-0 w-full">
                      <div className="relative hidden md:flex md:flex-row w-full h-max border-b-2 border-[#26f5d8]">
                        <p className="relative flex flex-col justify-left w-[150px] w-min-[130px] px-2 text-white text-[15px] font-bold">
                          Submission ID
                        </p>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Staked REKT
                        </h2>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Earnings ({tokenNative})
                        </h2>
                        <h2 className="relative flex flex-col justify-left w-[220px] w-min-[200px] px-2 text-white text-center text-[15px] font-bold">
                          Claim Bribe/Unstake REKT
                        </h2>
                        <p className="absolute flex flex-col mt-[-20px] right-[35px] text-gray-500 text-[14px] font-bold">
                          *After session ends
                        </p>
                      </div>
                    </div>
                    <div className="relative z-0 w-full">{pastVoteDiv}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLoadingVotes ? (
                  <div className="w-full mt-10 flex flex-col items-center justify-center">
                    <LoadingSkeleton />
                  </div>
                ) : (
                  <div className="mt-5 flex flex-row items-center justify-center">
                    <h1 className="text-white text-[16px] font-bold">
                      Nothing to unstake & claim!
                    </h1>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* </> */}
        {/* Submitted */}
        <div className="flex flex-col items-center pb-2 mt-10 mb-2 justify-center">
          <div className="relative mt-2 px-1 flex-col items-center items-center overflow-x-hidden max-h-[500px]">
            <div className="pt-4 mt-2 flex flex-col md:flex-row items-center justify-center">
              <h1 className="text-[#26f5d8] text-[25px] uppercase font-extrabold tracking-wider">
                {/* Submission History & &nbsp; */}
              </h1>
              <h1 className="text-[#26f5d8] text-[25px] uppercase font-extrabold tracking-wider">
                Withdraw&nbsp;
                <span className="text-[#ff31e9]">BRIBEs&nbsp;</span>
              </h1>
            </div>
            {pastSubsDiv.length > 0 ? (
              <>
                <div className="mt-5 relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                  <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                    <div className="py-2 pt-5 relative flex flex-row z-0 w-full">
                      <div className="relative hidden md:flex md:flex-row w-full h-max border-b-2 border-[#26f5d8]">
                        <p className="relative flex flex-col justify-left w-[150px] w-min-[130px] px-2 text-white text-[15px] font-bold">
                          Submission ID
                        </p>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Votes Received
                        </h2>
                        <h2 className="relative flex flex-col justify-left w-[180px] w-min-[150px] px-2 text-white text-[15px] font-bold">
                          Bribe ({tokenNative})
                        </h2>
                        <h2 className="relative flex flex-col justify-left w-[220px] w-min-[200px] px-2 text-white text-center text-[15px] font-bold">
                          Withdraw Bribe
                        </h2>
                        <p className="absolute flex flex-col mt-[-20px] right-[35px] text-gray-500 text-[14px] font-bold">
                          *After session ends
                        </p>
                      </div>
                    </div>
                    <div className="relative z-0 w-full">{pastSubsDiv}</div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLoadingSubs ? (
                  <div className="w-full mt-10 flex flex-col items-center justify-center">
                    <LoadingSkeleton />
                  </div>
                ) : (
                  <div className="mt-5 flex flex-row items-center justify-center">
                    <h1 className="text-white text-[16px] font-bold">
                      No bribe to withdraw!
                    </h1>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* )} */}
      </div>
    </>
  );
}
