import { UseContractInfo } from "../web3/ContractContext";

const Leaderboard = (props) => {
  const { tokenNative } = UseContractInfo();
  return (
    <>
      <div className="mt-5 px-3 py-3 w-full flex flex-col items-center justify-center">
        <h1 className="text-[30px] text-[#26f5d8] text-center uppercase px-4 py-4 text-xl md:text-2xl font-extrabold">
          Votes Leaderboard
        </h1>
        {props.div.length > 0 ? (
          <>
            <div className="relative flex flex-row w-full items-center justify-center">
              <div className="relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1">
                <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                  <div className="relative z-0 w-full">
                    <div className="relative hidden md:flex justify-start ">
                      <span className="flex flex-col px-2 py-2 w-[60px] min-w-[40px]">
                        <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          Rank
                        </p>
                      </span>
                      <span
                        className="flex flex-col px-2 py-2 w-fit md:w-[60px] md:min-w-[40px]"
                        //   onClick={() => setSortField([1, sortedName, 0])}
                      >
                        <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          ID
                        </p>

                        {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo> */}
                      </span>
                      <span
                        className="flex flex-col px-2 py-2 w-fit md:w-[150px] md:min-w-[120px]"
                        //   onClick={() => setSortField([12, sortedREKTp, 1])}
                      >
                        <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          Rekt ({tokenNative})
                        </p>
                        {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3"></Logo> */}
                      </span>
                      <span
                        className="flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]"
                        //   onClick={() => setSortField([13, sortedREKTb, 2])}
                      >
                        <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          Bribe ({tokenNative})
                        </p>
                        {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
                      </span>
                      <span
                        className="flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]"
                        //   onClick={() => setSortField([13, sortedREKTb, 2])}
                      >
                        <p className="text-left text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          Votes
                        </p>
                        {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
                      </span>
                      {/* </span> */}
                      <span className="flex px-2 w-full md:w-[200px] md:min-w-[180px] justify-center md:justify-start items-center">
                        <p className="text-[14px] text-center text-white font-bold uppercase hover:text-gray-200">
                          Transactions
                        </p>
                      </span>
                      {/* </span> */}
                      {/* <span className="flex flex-row w-full"> */}
                      <span
                        className="flex flex-col justify-center px-2 py-2 md:w-[120px] md:min-w-[100px]"
                        //   onClick={() => setSortField([13, sortedREKTb, 2])}
                      >
                        <p className="text-center text-[14px] text-white font-bold uppercase hover:text-gray-200">
                          Boost
                        </p>
                        {/* <Logo className="mt-1.5 ml-1 arrows w-3 h-3 "></Logo> */}
                      </span>
                    </div>
                  </div>
                  <div className="relative z-0 w-full">{props.div}</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h1 className="text-white text-center text-[20px] font-bold">
            No votes yet!
          </h1>
        )}
      </div>
    </>
  );
};

export default Leaderboard;
