import React, { useState, useEffect, useCallback, useContext } from "react";
import { ReactComponent as Logo } from "../public/images/sortPyramids.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import PopSubmit from "../components/popSubmit";
import RektTxnDiv from "../components/rektxndiv";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";
import Portfolio from "../components/portfolioDiv";
import { FaunaDatabase, UseFaunaDatabase } from "../utils/Database";
import PortfolioChart from "../components/portfolioChart";

export function Submit({ children }) {
	const {
		connected,
		currAddress,
		currChainId,
		wrongNetwork,
		comingSoon,
		currProvider,
		currSigner
	} = UseInfoUser();
	const {
		initiated,
		unrektusSession,
		unrektusContract,
		currentStatus,
		checkStatus,
		sessionEnded,
		handleSubmit,
		getSubmissionPropertiesAll
	} = UseContractInfo();
	const {
		rektPower,
		getRektPower,
		unRektness,
		setUnRektness,
		data,
		isLoading,
		errorTxn,
		queriesEnable,
		setQueriesEnable,
		howRektPressed,
		setHowRektPressed,
		userCurrentSubmission,
		getUserPastSubmissions
	} = UseUserPortfolio();
	const { sessionExists, addSessionData, updateSessionData } =
		UseFaunaDatabase();

	const forceRender = useCallback(() => updateState({}), []);
	const [, updateState] = useState();

	const [userInfo, setUserInfo] = useState(null);
	// const [rektData, setRektData] = useState(null);
	const [rektTxns, setrektTxns] = useState([]);
	const [rektDivs, setRektDivs] = useState([]);

	const [unrektNumber, setUnrektNumber] = useState(0);
	const [nbrekt, setNbRekt] = useState(0);
	const [nbmadeit, setNbMadeit] = useState(0);

	const [goAutoparse, setGoAutoParse] = useState(false);
	const [parsingData, setParsingData] = useState(false);

	const [rektxnpopup, setrektxnpopup] = useState(0);
	const [popupSubmit, setPopupSubmit] = useState(false);
	const [transactionIsSuccess, setTransactionIsSuccess] = useState(null);

	const [sortField, setSortField] = useState([13, 1, 2]);
	const [sortedName, setsortedName] = useState(0);
	const [sortedREKTp, setsortedREKTp] = useState(0);
	const [sortedREKTb, setsortedREKTb] = useState(0);

	// const [addi, setAddi] = useState(currAddress);
	// const [chain, setChainId] = useState(currChainId);

	//   useEffect(() => {
	//     if (addi != currAddress) setSigner(currAddress);
	//     if (chain != currChainId) setChainId(currChainId);
	//   }, [currAddress, currChainId]);
	function handleSubmitPop(txnId, rektTxn) {
		setPopupSubmit(true);
		setrektxnpopup(rektTxn);
		setTransactionIsSuccess(null);
	}

	const handleCheckSubmission = async () => {
		// console.log("handleCheckSubmission");
		// const alreadySubmitted = false;
		// const latestSubmissionSessionId = 0;
		var userInfo = {
			alreadySubmitted: false,
			currentSubmissionCounts: 0,
			currentSubmissionInfo: [],
			won: false,
			wonSubmissions: []
		};
		if (unrektusContract) {
			const { userCurrentSubmission, userPastSubmission } =
				await getUserPastSubmissions();

			// console.log(userCurrentSubmission);
			// console.log(userPastSubmission);
			userInfo = {
				alreadySubmitted: userCurrentSubmission.alreadySubmitted,
				currentSubmissionCounts: userCurrentSubmission.currentSubmissionCounts,
				currentSubmissionInfo: userCurrentSubmission.currentSubmissionInfo,
				won: userPastSubmission.won,
				wonSubmissions: userPastSubmission.wonSubmissions
			};
			// console.log(
			//   "already submitted",
			//   userInfo.alreadySubmitted,
			//   userInfo.currentSubmissionCounts
			// );
			// console.log(currentSubmissionInfo);
			// console.log("won", userInfo.won);
			// console.log(userInfo.wonSubmissions);
			// console.log(userCurrentSubmission);
			// console.log(userPastSubmission);
		}
		return userInfo;
	};

	const checkCurrentSubmissions = (submission, userInfo) => {
		var condition = false;
		// if (userInfo.currentSubmissionInfo.length > 0) {
		userInfo.currentSubmissionInfo.forEach((sub) => {
			if (sub.buyTxn === submission[10] && sub.sellTxn === submission[11]) {
				// console.log(submission);
				// console.log(sub);
				condition = true;
				return true;
			}
		});
		// }
		return condition;
	};

	function checkPastWins(submission, userInfo) {
		var condition = false;
		var totalWon = 0;
		// if (userInfo.wonSubmissions.length > 0) {
		userInfo.wonSubmissions.forEach((won) => {
			if (won.buyTxn === submission[10] && won.sellTxn === submission[11]) {
				// console.log(submission);
				// console.log(won);
				condition = true;
				totalWon = won.totalPotWon;
				return true;
			}
		});
		// }
		return { condition, totalWon };
	}

	function removeDuplicates(array) {
		let input = {};
		let output = [];

		for (let i = 0; i < array.length; i++) {
			let el = array[i];

			if (!(el in input)) {
				input[el] = true;
				output.push(el);
			}
		}
		return output;
	}

	function getDuplicates(arr) {
		const hashTable = {};
		const duplicate = [];
		arr.forEach((item) => {
			if (hashTable[item.hash]) {
				if (hashTable[item.hash] === 1) {
					duplicate.push(item);
				}
				hashTable[item.hash] = hashTable[item.hash] + 1;
			} else {
				hashTable[item.hash] = 1;
			}
		});
		return duplicate;
	}
	//seperate function cuz binance doesnt do in/out transaction hash
	function ParseAllTxnsBinance() {
		// same transaction hash (token transfer multi for single purchase)
		//transaction hash how much paid (gotta check in or out)from is currAddress then => out
		//
	}
	function ParseAllTxns(duplicates, allElligibleTxns, currAddress) {
		var resarray = [];
		let res = duplicates
			? duplicates.forEach((itemDuplicate) => {
					var count = 0;
					let ress = allElligibleTxns
						? allElligibleTxns.forEach((itemEachTxn) => {
								if (
									itemEachTxn.hash === itemDuplicate.hash &&
									itemEachTxn.tokenSymbol.toUpperCase() !==
										itemDuplicate.tokenSymbol.toUpperCase()
								) {
									var arraytemp = [];
									var valItem = 0;
									if (itemEachTxn.tokenSymbol === "USDC") {
										valItem = 1e-6 * itemEachTxn.value;
									} else if (itemEachTxn.tokenSymbol === "$SAVIOR") {
										valItem = parseInt(itemEachTxn.value);
									} else {
										valItem = 1e-18 * itemEachTxn.value;
									}
									var valDupe;
									if (itemDuplicate.tokenSymbol === "USDC") {
										valDupe = 1e-6 * itemDuplicate.value;
									} else if (itemDuplicate.tokenSymbol === "$SAVIOR") {
										valDupe = parseInt(itemDuplicate.value);
									} else {
										valDupe = 1e-18 * itemDuplicate.value;
									}
									// console.log(val);
									// console.log(
									//   itemDuplicate.tokenSymbol,
									//   itemEachTxn.tokenSymbol
									// );
									// console.log(val);
									if (!isNaN(valDupe) && !isNaN(valItem)) {
										arraytemp.splice(0, 0, valItem);
										arraytemp.splice(1, 0, itemEachTxn.tokenSymbol);
										arraytemp.splice(2, 0, valDupe);
										arraytemp.splice(3, 0, itemDuplicate.tokenSymbol);
										arraytemp.splice(4, 0, itemDuplicate.blockNumber);
										arraytemp.splice(5, 0, itemDuplicate.hash);
										resarray.push(arraytemp);
									}
								}
						  })
						: null;
			  })
			: null;
		// remove elements with length != 6
		resarray = resarray.reduce((p, c) => (c.length === 6 && p.push(c), p), []);
		// console.log(resarray);
		return resarray;
	}
	function getRektTransactions(parsedTxn) {
		var txnHashSold = {};
		var useBreak = false;
		var rektedTxns = [];
		var txnID = 0;
		var runningAmountBuy = 0;
		var runningAmountSell = 0;
		var arr = parsedTxn
			? parsedTxn.forEach((txn) => {
					var bought = txn[3]; //token symbol bought
					var boughtAmt = txn[2]; //token amount
					var paid = txn[1]; // Paid token
					var paidAmt = txn[0]; //amount paid
					// if (paid !== USDC) {
					// }
					runningAmountBuy = 0;
					useBreak = false;
					if (
						!bought.toUpperCase().includes("USDC") &&
						!bought.toUpperCase().includes("USDT") &&
						!bought.toUpperCase().includes("DAI")
					) {
						var arry = parsedTxn
							? parsedTxn.forEach((dupe) => {
									if (useBreak) {
										return;
									}
									var sold = dupe[1];
									var soldAmt = dupe[0]; //token amount
									var back = dupe[3]; // back token
									var backAmt = dupe[2]; //amount back
									if (
										txn[5].toLowerCase() != dupe[5].toLowerCase() && //not same trnx hash
										dupe[4] > txn[4] && // block is new than original buy
										bought === sold &&
										paid === back &&
										bought !== paid &&
										bought != back &&
										sold !== back &&
										sold != paid &&
										boughtAmt > 0 &&
										soldAmt > 0 &&
										paidAmt > 0 &&
										backAmt > 0 &&
										!bought.toLowerCase().includes("lp") &&
										!sold.toLowerCase().includes("lp") &&
										!paid.toLowerCase().includes("lp") &&
										!back.toLowerCase().includes("lp")
										// &&
										// !bought.includes("-") &&
										// !sold.includes("-")
										// &&
										// !paid.includes("-") &&
										// !back.includes("-")
									) {
										// console.log("Buy", bought, boughtAmt, paid, paidAmt);
										// console.log("Sell", sold, soldAmt, back, backAmt);
										if (dupe[5] in txnHashSold) {
										} else {
											// console.log("Buy", txn);
											// console.log("Sell", dupe);
											if (boughtAmt < soldAmt) {
												useBreak = true;
												if (runningAmountSell + boughtAmt >= soldAmt) {
													txnHashSold[dupe[5]] = dupe[5]; // add to hashtable
													runningAmountSell = 0;
												}
												if (runningAmountSell + boughtAmt <= soldAmt) {
													runningAmountSell += boughtAmt;

													var buyRatio = parseFloat(paidAmt / boughtAmt);
													var sellRatio = parseFloat(backAmt / soldAmt);

													var rekttemp = [];
													rekttemp.splice(0, 0, paidAmt); //Amount paid
													rekttemp.splice(1, 0, paid); //Symbol of token used to buy with
													rekttemp.splice(2, 0, boughtAmt); //For how much token bought
													rekttemp.splice(3, 0, bought); //of which coin symbol

													rekttemp.splice(4, 0, soldAmt); //Amount sold
													rekttemp.splice(5, 0, sold); //of which coin symbol
													rekttemp.splice(6, 0, backAmt); //For how much Amount gotten back
													rekttemp.splice(7, 0, back); //Symbol of token used to buy with in the first place

													rekttemp.splice(8, 0, parseFloat(buyRatio)); //ratio buy
													rekttemp.splice(9, 0, parseFloat(sellRatio)); //ratio sell

													rekttemp.splice(10, 0, txn[5]); // Txn hash buy
													rekttemp.splice(11, 0, dupe[5]); // Txn hash sell

													var ratio = parseFloat(sellRatio / buyRatio);
													var rekt;
													if (ratio >= 1) {
														rekt = "madeit";
													} else {
														rekt = "rekt";
													}
													var sellCost;
													var buyCost;

													sellCost = boughtAmt * sellRatio;
													buyCost = boughtAmt * buyRatio;

													var unRektusPotential = sellCost - buyCost; //either -ve or positive

													rekttemp.splice(12, 0, ratio); //
													rekttemp.splice(13, 0, unRektusPotential); // Txn
													rekttemp.splice(14, 0, rekt); //
													rekttemp.splice(16, 0, currAddress); // Txn
													rekttemp.splice(15, 0, txnID++);
													rektedTxns.push(rekttemp);
												}
											} else {
												if (runningAmountBuy + soldAmt >= boughtAmt) {
													useBreak = true;
												}
												if (runningAmountBuy + soldAmt <= boughtAmt) {
													runningAmountBuy += soldAmt;
													txnHashSold[dupe[5]] = dupe[5];

													var buyRatio = parseFloat(paidAmt / boughtAmt);
													var sellRatio = parseFloat(backAmt / soldAmt);

													var rekttemp = [];
													rekttemp.splice(0, 0, paidAmt); //Amount paid
													rekttemp.splice(1, 0, paid); //Symbol of token used to buy with
													rekttemp.splice(2, 0, boughtAmt); //For how much token bought
													rekttemp.splice(3, 0, bought); //of which coin symbol

													rekttemp.splice(4, 0, soldAmt); //Amount sold
													rekttemp.splice(5, 0, sold); //of which coin symbol
													rekttemp.splice(6, 0, backAmt); //For how much Amount gotten back
													rekttemp.splice(7, 0, back); //Symbol of token used to buy with in the first place

													rekttemp.splice(8, 0, parseFloat(buyRatio)); //ratio buy
													rekttemp.splice(9, 0, parseFloat(sellRatio)); //ratio sell

													rekttemp.splice(10, 0, txn[5]); // Txn hash buy
													rekttemp.splice(11, 0, dupe[5]); // Txn hash sell

													var ratio = parseFloat(sellRatio / buyRatio);
													var rekt;
													if (ratio >= 1) {
														rekt = "madeit";
													} else {
														rekt = "rekt";
													}
													var sellCost;
													var buyCost;

													sellCost = soldAmt * sellRatio;
													buyCost = soldAmt * buyRatio;

													var unRektusPotential = sellCost - buyCost; //either -ve or positive

													rekttemp.splice(12, 0, ratio); //
													rekttemp.splice(13, 0, unRektusPotential); // Txn
													rekttemp.splice(14, 0, rekt); //
													rekttemp.splice(15, 0, txnID++);
													rekttemp.splice(16, 0, currAddress); // Txn
													rektedTxns.push(rekttemp);
												}
											}
										}
									}
							  })
							: null;
					}
			  })
			: null;
		// console.log(rektedTxns.length);
		setrektTxns(rektedTxns);
		// console.log(rektedTxns);
		return rektedTxns;
	}
	function sortFunction(a, b) {
		if (a[sortField[0]] === b[sortField[0]]) {
			return 0;
		} else {
			console.log(sortedREKTb);

			if (sortField[1] === 1) {
				if (sortField[2] === 0) {
					setsortedName(0);
				} else if (sortField[2] === 1) {
					setsortedREKTp(0);
				} else if (sortField[2] === 2) {
					setsortedREKTb(0);
				}
				return a[sortField[0]] < b[sortField[0]] ? -1 : 1;
			} else if (sortField[1] === 0) {
				if (sortField[2] === 0) {
					setsortedName(1);
				} else if (sortField[2] === 1) {
					setsortedREKTp(1);
				} else if (sortField[2] === 2) {
					setsortedREKTb(1);
				}
				return a[sortField[0]] > b[sortField[0]] ? -1 : 1;
			}
		}
	}

	function getRektDivs(rektedtxns, userInfo) {
		// console.log(userInfo);
		var acc = [];
		// console.log(rektedtxns);

		// console.log(parseInt(rektedtxns.length));
		// console.log(rektedtxns);
		setUnrektNumber(parseInt(rektedtxns.length));
		rektedtxns.sort(sortFunction);

		var nbRekt = 0;
		var nbMadeit = 0;
		var ratioTotal = 0;

		var arr = rektedtxns
			? rektedtxns.forEach((rektTxn) => {
					// var ratio = rektTxn[12]; //rektTxn[9] / rektTxn[8];
					var UnrektusPotential = rektTxn[13];
					var rektyn = rektTxn[14];
					var txnID = rektTxn[15];

					// var buyTxn = rektTxn[10];
					// var sellTxn = rektTxn[11];
					// console.log(rektTxn);

					if (rektyn === "rekt") {
						nbRekt++;
					} else if (rektyn === "madeit") {
						nbMadeit++;
					}
					ratioTotal += rektTxn[12];

					var { condition, totalWon } = checkPastWins(rektTxn, userInfo);

					// if (condition === true) {
					//   console.log(condition, totalWon);
					//   console.log(rektTxn);
					// }
					rektTxn.splice(17, 0, totalWon);
					var submitted = checkCurrentSubmissions(rektTxn, userInfo);

					acc.push(
						<div
							className='pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400'
							key={txnID}
						>
							<RektTxnDiv rektxn={rektTxn} currentChain={currChainId} />
							<span className='flex flex-col px-2 py-5 justify-center items-center w-[120px] max-w-[100px]'>
								{!wrongNetwork && !comingSoon && !sessionEnded ? (
									<>
										{parseInt(UnrektusPotential) < 0 ? (
											<>
												{submitted === true ? (
													<button className='text-[#26f5d8] ml-1 cursor-default animate-pulse'>
														<a href='/vote'>{"Manage"}</a>
													</button>
												) : (
													<>
														{userInfo.currentSubmissionCounts < 5 && (
															<>
																{condition === true ? (
																	<>
																		<button
																			className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
																			onClick={() => {
																				handleSubmitPop(txnID, rektTxn);
																			}}
																		>
																			{"Submit"}
																		</button>
																		<p className='text-[13px] text-[#26f5d8] cursor-default animate-pulse'>
																			Past Winner
																		</p>
																	</>
																) : (
																	<>
																		<button
																			className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
																			onClick={() => {
																				handleSubmitPop(txnID, rektTxn);
																			}}
																		>
																			{"Submit"}
																		</button>
																	</>
																)}
															</>
														)}
													</>
												)}
											</>
										) : (
											<></>
										)}
									</>
								) : (
									<></>
								)}
							</span>
						</div>
					);
			  })
			: "";

		// console.log(rektedtxns.length)
		setNbRekt(nbRekt);
		setNbMadeit(nbMadeit);
		setRektDivs(acc);
		setUnRektness(nbRekt, nbMadeit, ratioTotal);
		// forceRender();
		// console.log(acc);
		// objs.sort((a,b) => a.last_nom - b.last_nom);
		// return acc;
		setParsingData(false);
	}

	function AutoParse(userInfo) {
		// console.log("Auto Parsing");
		setHowRektPressed(1);
		var arrCurrent = [];
		var resarray = [];
		var col = 0;
		var row = 0;
		var idx = 0;
		// console.log(data.data.result);
		let arr = data?.data?.result
			? data.data.result.forEach((element) => {
					arrCurrent = element;
					let arrr = data?.data?.result
						? data.data.result.forEach((elementSub) => {
								idx++;
								if (elementSub.hash === element.hash) {
									resarray.push(elementSub);
									row++;
								}
						  })
						: null;
			  })
			: null;
		// console.log(resarray);
		// trim doubles:
		var allElligibleTxns = resarray.filter((v, i, a) => a.indexOf(v) === i);
		// console.log(allElligibleTxns);
		var duplicates = getDuplicates(allElligibleTxns);
		// console.log(duplicates);

		var parsedTxn = ParseAllTxns(duplicates, allElligibleTxns);
		// var parsedTxn = ParseAllTxnsDoge(allElligibleTxns);
		// console.log(parsedTxn);
		// var removeDup = removeDuplicates(parsedTxn);
		const rektedtxns = getRektTransactions(parsedTxn);
		// console.log("already submitted", submissionFound);
		setUserInfo(userInfo);
		getRektDivs(rektedtxns, userInfo);
		// setRektDivs(getRektDivs());
	}

	async function parsedDivsFinal() {
		const userInfo = await handleCheckSubmission();
		AutoParse(userInfo);
	}

	useEffect(() => {
		let isCurrent = true;

		if (isCurrent) {
			if (transactionIsSuccess === true) {
				// console.log("transaction succcess... reloading divs");
				checkStatus();
				getSubmissionPropertiesAll();
				setGoAutoParse(true);
			}
			// setRektDivs([]);
			// setrektTxns([]);
			// setNbRekt(0);
			// setNbMadeit(0);
			// setUnrektNumber(0);
		}
		return () => {
			isCurrent = false;
		};
	}, [transactionIsSuccess]);

	useEffect(() => {
		let isCurrent = true;

		if (isCurrent) {
			if (howRektPressed > 0) {
				// console.log("changeDetected... reloading divs");
				// console.log(howRektPressed);
				setGoAutoParse(true);
			}
			return () => {
				isCurrent = false;
			};
		}
	}, [data]);

	useEffect(() => {
		if (currentStatus) {
			if (howRektPressed > 0) {
				setGoAutoParse(true);
			}
		}
	}, [currentStatus]);

	useEffect(() => {
		if (goAutoparse === true) {
			// run AutoParse() if a submission has been confirmed
			const autoParseData = async () => {
				// console.log("go auto parsing");
				setParsingData(true);
				await parsedDivsFinal();
				setGoAutoParse(false);
			};
			autoParseData().catch(console.error);
		}
	}, [goAutoparse]);

	useEffect(() => {
		getRektDivs(rektTxns, userInfo);
	}, [sortField]);

	function disbaleScroll() {
		if (popupSubmit) {
			document.body.style.overflow = "hidden";
		}
	}

	function enableScroll() {
		if (!popupSubmit) {
			document.body.style.overflow = "initial";
		}
	}

	return (
		<>
			<div className='mt-5 w-full flex flex-col justify-center '>
				{!connected && (
					<div className='flex flex-col md:flex-row justify-center items-center'>
						<h1 className='text-[#ff31e9] text-[16px] md:text-[24px] font-bold'>
							Connect Wallet&nbsp;
						</h1>
						<h1 className='text-white text-[16px] md:text-[24px] font-bold'>
							to discover your&nbsp;
						</h1>
						<h1 className='text-[#26f5d8] text-[16px] md:text-[24px] font-bold'>
							Unrektus potential!
						</h1>
					</div>
				)}
				{comingSoon && (
					<div className='flex flex-row justify-center '>
						<div className=' flex flex-col justify-center mb-[50px] '>
							<h1 className='text-[#23ff7b] text-[20px] font-bold'>
								Coming Soon on this chain
							</h1>
						</div>
					</div>
				)}
				{connected && !wrongNetwork && !comingSoon && (
					<>
						{popupSubmit && (
							<PopSubmit
								openPopup={popupSubmit}
								closepopup={setPopupSubmit}
								rektxnpopup={rektxnpopup}
								handleSubmit={handleSubmit}
								success={setTransactionIsSuccess}
							>
								{" "}
								{disbaleScroll()}
							</PopSubmit>
						)}
						{!popupSubmit && enableScroll()}

						<div className='px-3 py-3 w-full flex flex-col items-center justify-center'>
							{/* GET MY UNREKTUS POTENTIAL BUTTON */}
							{!data ? (
								<>
									{queriesEnable === false && (
										<div className='flex flex-col items-center justify-center mt-8 '>
											<button
												className='z-10 relative flex justify-center px-6 py-2  hover:bg-[#d430b0]:opacity-50 text-white text-2xl border-2 border-pink-600 rounded-lg'
												onClick={() => {
													setQueriesEnable(true);
												}}
											>
												Get my Urektus potential
											</button>
											<div className='z-0 absolute rounded-lg h-[60px] w-[320px] animate-pulse border-4 blur border-[#d430b0]'></div>
										</div>
									)}
									{/* GET API TXNs DATA */}
									{queriesEnable === true && (
										<div className='flex flex-col items-center justify-center'>
											<div className=' flex flex-col items-center justify-center  '>
												<LoadingLogo className='mt-12 h-[100px] w-[100px] animate-spin'></LoadingLogo>
												<h2 className='text-white text-[16px]'>
													Calculating your Unrektability...
												</h2>
												{isLoading ? (
													<h2 className='text-white text-[16px]'></h2>
												) : (
													errorTxn && (
														<h2 className='text-white text-[16px]'>
															Experiencing network issues?
														</h2>
													)
												)}
											</div>
										</div>
									)}
								</>
							) : (
								<>
									{howRektPressed > 0 ? (
										<>
											{parsingData ? (
												<div className='flex flex-col items-center justify-center'>
													<div className=' flex flex-col items-center justify-center  '>
														<LoadingLogo className='mt-12 h-[100px] w-[100px] animate-spin'></LoadingLogo>
														<h2 className='text-white text-[16px]'>
															Loading Data...
														</h2>
													</div>
												</div>
											) : (
												<>
													<div className='flex-col items-center justify-center'>
														<PortfolioChart unRektness={unRektness} />
													</div>
													<div className='relative w-full flex h-20 mt-2 mb-6 first-letter:items-center justify-center'>
														<div className='absolute flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1'>
															<div className='z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'>
																{nbrekt > 0 ? (
																	<>
																		<p className='text-xl md:text-3xl font-bold text-white flex flex-row items-center bg-[#110a26]'>
																			YOU GOT&nbsp;
																			<span className='text-[#ff31e9]'>
																				{" "}
																				REKT!&nbsp;
																			</span>
																		</p>
																		<p className='text-xl md:text-3xl font-bold text-white flex flex-row items-center bg-[#110a26]'>
																			Unrekt yourself on
																			<span className='text-[#ff31e9] px-2'>
																				{nbrekt}
																			</span>
																			<span>trades!</span>
																		</p>
																	</>
																) : (
																	<>
																		<h1 className='text-xl md:text-2xl font-bold text-white text-center '>
																			You have not been REKT (yet), WELLDONE !
																		</h1>
																	</>
																)}
															</div>
														</div>
													</div>
												</>
											)}
											{rektDivs.length > 0 && (
												<>
													<div className='mt-4 flex-col items-center justify-center'>
														<div className='relative flex flex-row items-center justify-center'>
															<div className='flex flex-col items-center justify-center'>
																{/* <p className="mb-2 md:hidden text-white justify-start mr-3">
                                  Sort Options
                                </p> */}
																<nav className='relative w-full flex flex-wrap justify-start items-center'>
																	<button
																		className='sm:w-[100px] md:w-[400px] md:max-w-[340px] px-2 text-left relative flex font-bold text-[16px] text-white  hover:text-gray-200'
																		onClick={() =>
																			setSortField([1, sortedName, 0])
																		}
																	>
																		Name
																		<Logo className='mt-1.5 ml-1 arrows w-3 h-3'></Logo>
																	</button>
																	<button
																		className='px-2 sm:w-[100px] md:w-[200px] md:max-w-[180px] text-left relative flex font-bold text-[16px] text-white hover:text-gray-200'
																		onClick={() =>
																			setSortField([13, sortedREKTb, 2])
																		}
																	>
																		Profit / Loss
																		<Logo className='mt-1.5 ml-1 arrows w-3 h-3 '></Logo>
																	</button>
																	<button
																		className='px-2 sm:w-[100px] md:w-[150px] md:max-w-[130px] text-left relative flex font-bold text-[16px] text-white  hover:text-gray-200'
																		onClick={() =>
																			setSortField([12, sortedREKTp, 1])
																		}
																	>
																		Gainz {"(%)"}
																		<Logo className='mt-1.5 ml-1 arrows w-3 h-3'></Logo>
																	</button>
																	<span className='hidden md:flex'>
																		<button className='px-2 w-[200px] max-w-[180px] justify-center text-center relative flex font-bold text-[16px] text-white hover:text-gray-200'>
																			Transactions
																		</button>
																		<button className='px-2 w-[120px] max-w-[100px] justify-center text-center relative flex font-bold text-[16px] text-white hover:text-gray-200'>
																			Actions
																		</button>
																	</span>
																</nav>
															</div>
														</div>

														<div className='relative flex flex-row items-center justify-center'>
															<div className='relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1'>
																<div className='z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'>
																	<div className='relative z-0 w-full'>
																		{rektDivs}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</>
											)}
										</>
									) : (
										<>
											<div className='flex flex-col items-center justify-center '>
												<div className='z-0 absolute rounded-3xl  animate-pulse  h-[118px] w-[350px] border-8 blur border-[#d430b0]'></div>
												<button
													className='z-10  mt-4 mb-4 rounded-lg  bg-gradient-to-r from-[#D02374]/80 to-[#9434E7]/70 focus:ring focus:ring-pink-600 px-14 py-4 text-white font-bold '
													onClick={() => {
														setGoAutoParse(true);
													}}
												>
													<label className='z-20 text-[30px] font-bold animate-pulse hover:cursor-pointer'>
														ARE YOU REKT?
													</label>
													<p className='text-white text-[20px]'>Click</p>
												</button>
											</div>
											{/* <div className='relative w-full flex h-20 mt-6 mb-6 first-letter:items-center justify-center'>
												<div className='absolute flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1'>
													<div className='z-10 relative flex flex-col items-center justify-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'>
														<span className='text-xl md:text-3xl flex uppercase text-center items-center justify-center bg-[#110a26]'>
															<p className='text-white'>
																did you get&nbsp;
																<span className='text-[#ff31e9] uppercase'>
																	{" "}
																	REKT?&nbsp;
																</span>
																verify below!
															</p>
														</span>
													</div>
												</div>
											</div> */}
										</>
									)}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}
export default Submit;
