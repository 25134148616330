// import { useScrollTrigger } from "@mui/material";
import {
  LineChart,
  PieChart,
  Line,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
} from "recharts";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { useEffect, useState } from "react";
import { UseFaunaDatabase } from "../utils/Database";

const Analytics = () => {
  const {
    connected,
    currAddress,
    currChainId,
    wrongNetwork,
    comingSoon,
    currProvider,
    currSigner,
  } = UseInfoUser();
  const { databaseData } = UseFaunaDatabase();

  const [data, setData] = useState({
    sessionId: 0,
    submissionCount: 0,
    bribesCount: 0,
    votesCount: 0,
  });

  function mapDatabaseData() {
    // console.log("mapDatabaseData");
    const sessionArray = [];

    for (var i = 0; i < databaseData.length; i++) {
      const session = databaseData[i];
      // console.log(session);
      var total_sub = 0;
      var total_vot = 0;
      var total_brib = 0;
      if (session.submissionCount) total_sub = session.submissionCount;
      if (session.votesCount) total_vot = session.votesCount;
      if (session.bribesCount) total_brib = session.bribesCount;
      sessionArray.push({
        session: Number(session.sessionId),
        total_submissions: total_sub,
        total_votes: total_vot,
        total_bribes: total_brib,
      });
    }
    setData(sessionArray);
  }

  useEffect(() => {
    if (databaseData) {
      // updateAnalytics();
      mapDatabaseData(databaseData);
    }
  }, [databaseData]);

  return (
    <>
      <div className="w-full mt-5 flex flex-col justify-center">
        {/* {!connected && (
				<div className='flex flex-row justify-center mt-[100px] '>
					<h1 className='text-[#ff31e9] text-[20px] font-bold'>
						Connect Wallet
					</h1>
				</div>
			)} */}
        {comingSoon && (
          <div className="flex flex-col justify-center ">
            <div className=" flex flex-row justify-center mb-[50px] ">
              <h1 className="text-[#23ff7b] text-[20px] font-bold">
                Coming Soon on this chain
              </h1>
            </div>
          </div>
        )}
        {!wrongNetwork && !comingSoon && (
          <>
            {/* <Portfolio></Portfolio> */}
            {/* <div className="absolute z-10 mt-[-220px] ml-[490px] italic">
        <h1 className="text-[#FC42FF] font-bold text-[45px] uppercase">Stat</h1>
      </div> */}
            {/* <button
              className="text-[#4cff85]/0 text-[40px] uppercase font-extrabold "
              onClick={() => {
                //   GetSessionData();
                //   GetRektTxns();
              }}
            >
              show&nbsp;
            </button> */}

            {!comingSoon && (
              <div className="w-full flex flex-row  justify-center space-x-6 ">
                {/* line charts */}
                <div className="w-full flex flex-col items-center justify-center bg-[#080613]/60 rounded-3xl ">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="text-[#ff31e9] py-4 text-[30px] uppercase font-extrabold tracking-wider">
                      Session data
                    </h1>
                  </div>
                  <div className="w-full flex flex-col left-0  bg-[#080613]/60 rounded-3xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="flex flex-row items-center justify-center">
                        <h1 className="text-[#82ca9d] text-[30px]">
                          Number of submissions
                        </h1>
                      </div>
                      <ResponsiveContainer
                        width="100%"
                        height={250}
                        // className="flex w-[300px] md:w-[500px] lg:w-[700px] h-[250px] items-center justify-center"
                      >
                        <LineChart
                          data={data}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          {/* <CartesianGrid strokeDasharray="1 1" /> */}
                          <XAxis>
                            dataKey="session"
                            <Label
                              value="Unrektus session"
                              position="insideBottomRight"
                              dx={-60}
                              dy={10}
                              style={{ textAnchor: "middle" }}
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              angle={-90}
                              value="submissions"
                              position="outsideLeft"
                              dx={-45}
                              style={{ textAnchor: "middle" }}
                            />
                          </YAxis>
                          <Tooltip />
                          <Legend dy={10} style={{ textAnchor: "middle" }} />
                          <Line
                            type="monotone"
                            dataKey="total_submissions"
                            stroke="#82ca9d"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="mt-10 w-full flex flex-col left-0  bg-[#080613]/60 rounded-3xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="flex flex-row items-center justify-center">
                        <h1 className="text-[#8884d8] text-[30px]">
                          Total number of votes
                        </h1>
                      </div>
                      <ResponsiveContainer width="100%" height={250}>
                        <LineChart
                          data={data}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          {/* <CartesianGrid strokeDasharray="1 1" /> */}
                          <XAxis>
                            dataKey="session"
                            <Label
                              value="Unrektus session"
                              position="insideBottomRight"
                              dx={-60}
                              dy={10}
                              style={{ textAnchor: "middle" }}
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              angle={-90}
                              value="votes"
                              position="outsideLeft"
                              dx={-45}
                              style={{ textAnchor: "middle" }}
                            />
                          </YAxis>
                          <Tooltip />
                          <Legend dy={10} style={{ textAnchor: "middle" }} />
                          <Line
                            type="monotone"
                            dataKey="total_votes"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="mt-10 w-full text-[#ff5670] flex flex-col left-0  bg-[#080613]/60 rounded-3xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="flex flex-row items-center justify-center">
                        <h1 className="text-ff5670 text-[30px]">
                          Total bribed
                        </h1>
                      </div>
                      <ResponsiveContainer width="100%" height={250}>
                        <LineChart
                          width={700}
                          height={250}
                          data={data}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          {/* <CartesianGrid strokeDasharray="1 1" /> */}
                          <XAxis>
                            dataKey="session"
                            <Label
                              value="Unrektus session"
                              position="insideBottomRight"
                              dx={-60}
                              dy={10}
                              style={{ textAnchor: "middle" }}
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              angle={-90}
                              value="bribes"
                              position="outsideLeft"
                              dx={-45}
                              style={{ textAnchor: "middle" }}
                            />
                          </YAxis>
                          <Tooltip />
                          <Legend dy={10} style={{ textAnchor: "middle" }} />
                          <Line
                            type="monotone"
                            dataKey="total_bribes"
                            stroke="#ff5670"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Analytics;
