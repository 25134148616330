/* Api methods to call /functions */

const create = (data) => {
  return fetch("/.netlify/functions/session-create", {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
};

const readAll = () => {
  return fetch("/.netlify/functions/session-read-all").then((response) => {
    return response.json();
  });
};

const readById = (sessionId) => {
  return fetch(`/.netlify/functions/session-read-byId/${sessionId}`).then(
    (response) => {
      return response.json();
    }
  );
};

const update = (sessionId, data) => {
  return fetch(`/.netlify/functions/session-update/${sessionId}`, {
    body: JSON.stringify(data),
    method: "POST",
  }).then((response) => {
    return response.json();
  });
};

// const deleteTodo = (todoId) => {
//   return fetch(`/.netlify/functions/todos-delete/${todoId}`, {
//     method: 'POST',
//   }).then(response => {
//     return response.json()
//   })
// }

// const batchDeleteTodo = (todoIds) => {
//   return fetch(`/.netlify/functions/todos-delete-batch`, {
//     body: JSON.stringify({
//       ids: todoIds
//     }),
//     method: 'POST'
//   }).then(response => {
//     return response.json()
//   })
// }

export default {
  create: create,
  readAll: readAll,
  readById: readById,
  update: update,
};
