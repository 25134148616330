import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Tglogo } from "../public/images/tg-logo-midlight.svg";
import { ReactComponent as Twitterlogo } from "../public/images/twitter-logo-midlight.svg";
import { ReactComponent as Mediumlogo } from "../public/images/medium.svg";
import { ReactComponent as Gitbooklogo } from "../public/images/GitBook_logo.svg";
import { ReactComponent as UnrektusLogo } from "../public/images/unrektuslogo.svg";
import { ReactComponent as RektPower } from "../public/images/lightning.svg";

import { ReactComponent as GridVector } from "../public/images/NFT_GridVector.svg";
import { ReactComponent as GridImage } from "../public/images/NFT_Frame.svg";
import { FaExternalLinkAlt } from "react-icons/fa";

import Navbar from "./barNav";
import Timer from "./timer";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";
import PickWinner from "./pickWinner";
// const provider = Web3.givenProvider;
// const web3 = new Web3(provider);
// window.w3 = web3;

const Head = () => {
	const { connected, currAddress, currChainId, wrongNetwork } = UseInfoUser();
	const { isLoadingStatus, currentStatus, prizeValue, tokenNative } =
		UseContractInfo();
	const { rektPower, setUnRektness } = UseUserPortfolio();
	return (
		<>
			<div className='absolute -z-20 w-full top-0 left-0 opacity-20'>
				<div className='w-full object-cover'>
					<GridVector width='100%' height='100%' />
				</div>
			</div>

			<div className='w-full grid grid-row-2'>
				<div className='w-full mb-10 mt-[100px] md:mt-0 grid sm:grid-cols-1 md:grid-cols-3 items-start justify-around'>
					<div className='col-span-1 row-span-1 flex flex-col items-center mt-5 md:items-start'>
						<div className='w-full mx-10 mt-[-80px] flex flex-col justify-center md:justify-start '>
							<Link
								className='ml-[-20px] flex justify-center md:justify-start item-center'
								to='/'
							>
								<UnrektusLogo
									className='display-flex justify-start items-start'
									length='w-full'
									width='300'
									// viewBox="0 0 300 214"
								></UnrektusLogo>
							</Link>
							<div className='w-full mt-[-100px] flex justify-center md:justify-start pt-5 text-white'>
								<Twitterlogo
									onClick={() => window.open("https://twitter.com/unrektus")}
									className='cursor-pointer w-6 h-6 mx-5'
								></Twitterlogo>
								<Tglogo
									onClick={() => window.open("https://t.me/unrektus")}
									className='cursor-pointer w-6 h-6 mx-5'
								></Tglogo>
								<Mediumlogo
									onClick={() =>
										window.open(
											"https://medium.com/@bitcoinboulangerie/never-get-rekt-again-with-unrektus-95b9aac12b9d"
										)
									}
									className='cursor-pointer w-6 h-6 mx-5'
								></Mediumlogo>
								<Gitbooklogo
									onClick={() => window.open("https://docs.unrektus.fi/")}
									className='mt-1 cursor-pointer w-5 h-5 mx-5'
								></Gitbooklogo>
							</div>
						</div>
					</div>
					<div className='col-span-1 row-span-1 flex flex-col items-center justify-center mt-5'>
						{/* <div className="w-full border-[1px] border-[#26f5d8] px-4 py-2 rounded-xl flex flex-col items-center justify-center"> */}
						{/* <div className=" px-2 py-2 rounded-xl flex flex-col items-center justify-center w-[200px] h-[170px]"> */}
						{/* <div className="flex flex-col justify-center items-center"> */}
						{/* <div className="w-full md:border-[1px] border-[#26f5d8] px-4 py-2 rounded-xl flex flex-col items-center justify-center	top-16 right-0 text-white font-bold"> */}

						<div className='w-full flex flex-col items-center justify-center'>
							<Timer />
							{!isLoadingStatus && currentStatus && (
								<>
									<div className='flex border-2 mt-4 border-[#ff31e9]/75 bg-[#1b0928]/60 rounded-xl px-2 items-center justify-center py-2'>
										<h1 className='text-[25px] text-[#ff31e9] font-bold '>
											Prize &nbsp;&nbsp;
										</h1>
										<h1 className='text-[25px]  text-[#26f5d8] font-bold animate-pulse'>
											{Number(prizeValue).toFixed(2)}&nbsp;{tokenNative}
										</h1>
									</div>
									{prizeValue <= 0 && (
										<>
											<div className='flex flex-col items-center justify-center px-2 py-2'>
												<h1 className='mt-[-10px] text-center text-[16px] text-[#ff4940]'>
													*Winning pot is empty
												</h1>
												{/* {currAddress === currentStatus._rektMasterAddress && (
                          <button
                            className="w-[300px] text-center text-[#26f5d8] text-[20px] uppercase font-extrabold border-[1px] border-[#ff31e9] px-2 py-1 rounded-lg"
                            onClick={() => {
                              setSendPotPushed(true);
                            }}
                          >
                            Send Pot Money
                          </button>
                        )} */}
											</div>
										</>
									)}
								</>
							)}
							<div className=''>
								<PickWinner />
							</div>
						</div>

						{/* </div> */}
					</div>
					<div className='col-span-1 row-span-1 flex flex-row items-center mt-5 md:items-end md:mr-10 justify-center md:justify-end'>
						<div className='flex flex-col justify-end'>
							<div className='flex-row'>
								<w3m-network-switch />
								<w3m-button />
							</div>
							{wrongNetwork && (
								<div className='text-center'>
									<h1 className='text-[#FF0000] text-[14px] font-bold'>
										Connected to wrong chain
									</h1>
								</div>
							)}
							<div className='flex flex-row items-center justify-center text-white font-bold bg-gradient-to-r via-[#e4ce3c]/50 to-[#dcd322]/5 from-pink-600/5'>
								<p className='uppercase'>Unrektus power</p>
								{/* <div className="flex"> */}
								<RektPower className='mt-[3px] h-[20px] w-[20px] animate-pulse'></RektPower>
								<p className=''>{rektPower}</p>
								{/* </div> */}
							</div>
							<a
								href='https://wojak.fi/swap?chain=dogechain&outputCurrency=0x47022108d3Cd791D0492e05575B150Ae738b8215'
								target='_new'
								className='w-full mt-3 py-[3px] flex items-center justify-center text-white text-[15px] bg-gradient-to-r via-[#d430b0]/50 to-[#962ee4]/5 from-pink-600/5 rounded-lg font-bold hover:text-gray-200 '
							>
								BUY POWER [uREKT]
								<FaExternalLinkAlt className='ml-3' />
							</a>
						</div>
					</div>
				</div>
				<div className='w-full fixed top-0 md:relative px-0 mx-0 mb-5 flex items-center justify-center'>
					<div className='w-full md:bg-[#191236]/100 md:border-t-2 md:border-b-2 md:border-gray-500 py-4 flex flex-row items-center justify-start md:justify-center text-white'>
						<Navbar />
					</div>
				</div>
			</div>
		</>
	);
};

export default Head;
