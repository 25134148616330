import { useEffect, useState } from "react";
import axios from "axios";

import Countdown from "react-countdown";
import { UseContractInfo } from "../web3/ContractContext";
import IncreaseSession from "./increaseSession";

const Timer = () => {
	const {
		checkStatus,
		unrektusSession,
		sessionEnded,
		sessionDuration,
		winnnerPicked
	} = UseContractInfo();

	const [restart, setRestart] = useState(true);
	// const [sessionEnded, setSessionEnded] = useState(false);
	const [intervalSession, setIntervalSession] = useState(0);
	// const [intervalBreak, setIntervalBreak] = useState(0);
	// * 24 * 60 * 60 * 1000
	//24 * 60 * 60 * 1000);4000

	useEffect(() => {
		setIntervalSession(sessionDuration * 1000); // in hours
		// setIntervalBreak(breakDuration * 1000);
		// console.log(sessionEnded, sessionDuration);
		// console.log(sessionBreak, breakDuration);;
	}, [sessionDuration]);

	return (
		<>
			<div className='w-full flex flex-col md:flex-row items-center justify-center'>
				<div className='flex flex-row space-x-4'>
					<div className='relative flex flex-row items-center justify-center  space-x-4'>
						<h1 className='relative text-[25px] text-[#26f5d8] font-bold'>
							Session&nbsp;
						</h1>
						{/* {!sessionEnded && ( */}
						<h1 className='relative text-[25px] text-[#ff31e9] font-bold'>
							{unrektusSession}
						</h1>
						{/* )} */}
					</div>
					<div className='relative flex flex-row items-center justify-center space-x-4'>
						{!sessionEnded ? (
							<>
								{sessionDuration > 0 && (
									<h1 className='text-[20px]  border-2 border-[#23ff7b] px-2 md:px-4 rounded-lg text-[#23ff7b] font-bold animate-pulse'>
										&nbsp;Live
									</h1>
								)}
							</>
						) : (
							<>
								<h1 className='text-[20px] border-2 border-[#ff4940] px-2 md:px-4 rounded-lg text-[#ff4940] font-bold animate-pulse'>
									&nbsp;Closed
								</h1>
								{/* {fetchFunction()} */}
								{/* {sessionBreak ? (
                  <h1 className="text-[25px] z-20 border-2 border-[#ff4940] px-2 md:px-4 rounded-lg text-[#23ff7b] font-bold animate-pulse">
                    &nbsp;Break
                  </h1>
                ) : (
                  <>
                    <h1 className="text-[25px] z-20 border-2 border-[#ff4940] px-2 md:px-4 rounded-lg text-[#ff4940] font-bold animate-pulse">
                      &nbsp;Closed
                    </h1>
                  </>
                )} */}
							</>
						)}
					</div>
					<div className='md:ml-5 flex flex-row items-center justify-center space-x-4'>
						{!sessionEnded && sessionDuration >= 0 && (
							<div>
								<Countdown
									key={restart}
									className='z-20 text-[#4eef71] font-bold text-[25px]'
									date={Date.now() + intervalSession}
									autoStart={true}
									onComplete={() => {
										// setSessionEnded(true);
										setTimeout(() => {
											// console.log("Hello, World!");
											setRestart(!restart);
											// setUnrektusSession(unrektusSession + 1);
											// setSessionEnded(false);
										}, intervalSession);
										// console.log("New session:");
										// console.log(unrektusSession);
									}}
								/>
							</div>
						)}
						{/* {sessionEnded && breakDuration > 0 && (
            <div>
              <Countdown
                key={restart}
                className="z-20 text-[#23ff7b] font-extrabold text-[25px]"
                date={Date.now() + intervalBreak}
                autoStart={true}
                onComplete={() => {
                  // setSessionEnded(true);
                  setTimeout(() => {
                    // console.log("Hello, World!");
                    setRestart(!restart);
                    // setUnrektusSession(unrektusSession + 1);
                    // setSessionEnded(false);
                  }, intervalBreak);
                  // console.log("New session:");
                  // console.log(unrektusSession);
                }}
              />
            </div>
          )} */}
					</div>
				</div>
				{/* <IncreaseSession /> */}
			</div>
		</>
	);
};

export default Timer;
