export const REKT_HARDHAT = "0x7a2088a1bFc9d81c55368AE168C2C02570cB814F";
export const WNATIVE_HARDHAT = "0x09635F643e140090A9A8Dcd712eD6285858ceBef";
export const UNREKTUS_ADDRESS_HardHat =
  "0xc5a5C42992dECbae36851359345FE25997F5C42d";

export const REKT_MASTER_WALLET = "0x4b200e8E6340E17Ea4Bfd8650f19410bBBDF709e"; //krakouz
// "0xf39fd6e51aad88f6f4ce6ab8827279cfffb92266"; //hardaht
export const TREASURY_WALLET = "0x4b200e8E6340E17Ea4Bfd8650f19410bBBDF709e";

//FIXME fix the correct address for token
export const UNREKTUS_ADDRESS_DODGE =
  "0x4fe1b5c0753e54ada087b16f1cbd01223f747f8a"; //dodge deploy
export const REKT_DODGE = "0x47022108d3Cd791D0492e05575B150Ae738b8215"; //test REKT
export const WNATIVE_DODGE = "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101"; //wwdoge
// "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101"; // test wdoge

export const REKT_DODGE_TEST = "0x6f301941acf13997ef81e2f9e676f263d938b82d";
export const WNATIVE_DODGE_TEST = "0xca791a18fb95A5ebe792987e3687370334B03145";
export const UNREKTUS_ADDRESS_DODGE_TEST =
  "0x1C89F380cAC5dBef109202B02c79434D2d7E9709"; //dc

export const REKT_FTM = "0xa0CCDEc46De85568b38dAba2658b143Db83377c9";
export const WNATIVE_FTM = "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83";
export const UNREKTUS_ADDRESS_FTM =
  "0x0c8D8802d0EE2b28E42370580E6aa00a3938C47f"; //ftm

export const WNATIVE_ETH = "";
export const REKT_ETH = "";
export const UNREKTUS_ADDRESS_ETH = ""; //eth
// export const downBadBros_ADDRESS = '0x49fd2be640db2910c2fab69bb8531ab6e76127ff'; //hardhat local
export const UNREKTUS_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_rektMaster",
        type: "address",
      },
      {
        internalType: "address",
        name: "_treasury",
        type: "address",
      },
      {
        internalType: "address",
        name: "_rektContract",
        type: "address",
      },
      {
        internalType: "address",
        name: "_wNativeContract",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "submissionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "boostMultiplier",
        type: "uint256",
      },
    ],
    name: "BoostAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "submissionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bribeAmount",
        type: "uint256",
      },
    ],
    name: "BribeAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "txhash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "buyTx",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "sellTx",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amountRekt",
        type: "uint256",
      },
    ],
    name: "TXsAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "totalRektForVote",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "submissionId",
        type: "uint256",
      },
    ],
    name: "Voted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "submissionId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalWon",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalBribe",
        type: "uint256",
      },
    ],
    name: "WinnerPicked",
    type: "event",
  },
  {
    inputs: [],
    name: "REKT",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "boostMultiplier",
        type: "uint256",
      },
    ],
    name: "addBoost",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_rektContract",
        type: "address",
      },
    ],
    name: "addREKTContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_txHash",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_buyTx",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_sellTx",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "_txOwner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amountRekt",
        type: "uint256",
      },
    ],
    name: "addTxs",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_wrappedNativeContract",
        type: "address",
      },
    ],
    name: "addWrappedNATIVEContract",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "bribeTaxPercent",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "checkStatus",
    outputs: [
      {
        internalType: "address",
        name: "_rektContract",
        type: "address",
      },
      {
        internalType: "address",
        name: "_rektMasterAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_treasury",
        type: "address",
      },
      {
        internalType: "address",
        name: "_wrappedNativeAdresss",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "currentSessionId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_totalSubmissions",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_totalCurrentSubmissions",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalWinners",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_sessionDurationInHours",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_latestSessionIdIncreasedTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_currentWiningPot",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_currentHeighestVotes",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
    ],
    name: "claimBribe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getFeesInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "_potDivider",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_submissionFeeDivider",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_boostFeeDivider",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_bribeTaxPercent",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_txOwner",
        type: "address",
      },
    ],
    name: "getUserSubmission",
    outputs: [
      {
        internalType: "uint256",
        name: "currentSubmissionCounts",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "allSubmissions",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pickWinner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_feeDivider",
        type: "uint256",
      },
    ],
    name: "setBoostFeeDivider",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
    ],
    name: "setBribe",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_bribeTaxPercent",
        type: "uint256",
      },
    ],
    name: "setBribeTax",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_potDivider",
        type: "uint256",
      },
    ],
    name: "setPotDivider",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "_sessionDurationInHours",
        type: "uint16",
      },
    ],
    name: "setSessionDuration",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_feeDivider",
        type: "uint256",
      },
    ],
    name: "setSubmissionFeeDivider",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_txOwner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
    ],
    name: "showSubmitterInfo",
    outputs: [
      {
        internalType: "bool",
        name: "bribeSet",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "bribeWithdrew",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_voterAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
    ],
    name: "showVoteInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "_sessionId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalRektVoted",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "bribeClaimed",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "rektUnstaked",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_voterAddress",
        type: "address",
      },
    ],
    name: "showVoterInfo",
    outputs: [
      {
        internalType: "uint256[]",
        name: "activeVotes",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "votingHistory",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "submissionInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "sessionId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "txOwner",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "txHash",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "buyTx",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "sellTx",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "amountRekt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalBribe",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "boostX",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "boostedVoteReceived",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "actualVoteReceived",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalPotWon",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "totalCurrentSubmissions",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "totalSubmissions",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalWinningPot",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_wallet",
        type: "address",
      },
    ],
    name: "updateRektMasterWallet",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_totalRektForVote",
        type: "uint256",
      },
    ],
    name: "vote",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    name: "winnerInfo",
    outputs: [
      {
        internalType: "uint256",
        name: "submissionId",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "won",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "totalWon",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "winnerList",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_submissionId",
        type: "uint256",
      },
    ],
    name: "withDrawBribe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];
