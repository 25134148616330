// import { useScrollTrigger } from "@mui/material";

import { UseInfoUser } from "../web3/Web3Context";

import Portfolio from "../components/portfolioDiv";

const Portfolios = () => {
	const { connected, wrongNetwork, comingSoon } = UseInfoUser();

	return (
		<>
			<div className='w-full mt-5 flex flex-col justify-center'>
				{!connected && (
					<div className='flex flex-row justify-center mt-[50px]'>
						<h1 className='text-[#ff31e9] text-[20px] font-bold'>
							Connect Wallet
						</h1>
					</div>
				)}
				{comingSoon && (
					<div className='flex flex-col justify-center '>
						<div className=' flex flex-row justify-center mb-[50px] '>
							<h1 className='text-[#23ff7b] text-[20px] font-bold'>
								Coming Soon on this chain
							</h1>
						</div>
					</div>
				)}
				{connected && !wrongNetwork && !comingSoon && (
					<>
						<div className='px-3 py-3 w-full flex flex-col items-center justify-center'>
							<div className='flex flex-col items-center justify-center '>
								<div className='relative border-[2px] border-white rounded-xl py-3 bg-[#080613]/60 top-0 px-8 flex items-center justify-center '>
									<button className='text-[#26f5d8] text-2xl md:text-3xl uppercase font-extrabold '>
										Claim&nbsp;
									</button>
									{/* {!showPortfolio && (
              <button
                className="text-[#ff31e9] border-[1px] border-[#ff31e9] px-1 py-1 rounded-lg text-[20px] uppercase font-extrabold"
                onClick={() => {
                  setShowPortfolio(true);
                }}
              >
                Show
              </button>
            )} */}
									{/* {showPortfolio && (
              <button
                className="text-[#ff31e9] border-[1px] border-[#ff31e9] px-1 py-1 rounded-lg text-[20px] uppercase font-extrabold"
                onClick={() => {
                  setShowPortfolio(false);
                }}
              >
                Hide
              </button>
            )} */}
								</div>
							</div>
							<Portfolio></Portfolio>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default Portfolios;
