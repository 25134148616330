import React from "react";
import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

export default function VoteTxnDiv(props) {
	const { connected, currAddress, currChainId, wrongNetwork, comingSoon } =
		UseInfoUser();
	const { sessionEnded, tokenNative } = UseContractInfo();
	// var currentChain = props.currentChain;
	// var currentChain = "0x7d0"; //DC
	var sessionId = props.votetxndiv.sessionId;
	var amountRekt = props.votetxndiv.amountRekt;
	var buyTxn = props.votetxndiv.buyTxn;
	var sellTxn = props.votetxndiv.sellTxn;
	var totalBribe = props.votetxndiv.totalBribe;
	var totalVotes = props.votetxndiv.totalVotes;
	var boostX = props.votetxndiv.boostX;
	const wallet = props.votetxndiv.txOwner;
	var submissionId = props.votetxndiv.submissionId;

	const findChainExplorer = () => {
		var link = "";
		// console.log(currentChain);
		switch (currChainId) {
			case "0x7d0": //DC
				link = "https://explorer.dogechain.dog/tx/";
				break;
			case "0x238": //dc test
				link = "https://explorer.dogechain.dog/tx/";
				break;
			case "0xfa": //FTM
				link = "https://ftmscan.com/tx/";
				break;
			case "0x1": //eth
				link = "https://etherscan.io/tx/";
				break;
			default:
				link = "";
		}
		return link;
	};

	return (
		<>
			<span className='flex flex-row w-full mb-5 md:w-[570px] md:min-w-[460px]'>
				<span className='flex flex-col px-2 py-2 w-fit md:w-[60px] md:min-w-[40px] '>
					<p className='mb-2 md:hidden text-white mr-3'>ID</p>
					<p className='text-left text-[16px] text-white font-bold uppercase'>
						{submissionId}
					</p>
				</span>
				<span className='flex flex-col px-2 py-2 w-fit md:w-[150px] md:min-w-[120px]'>
					<p className='mb-2 md:hidden text-white mr-3'>
						Rekt by ({tokenNative})
					</p>
					<p className='text-left text-[16px] text-orange-400 font-bold'>
						{amountRekt}
					</p>
				</span>
				<span className='flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]'>
					<p className='mb-2 md:hidden text-white mr-3'>
						Bribe ({tokenNative})
					</p>
					<p className='text-left text-[16px] text-[#FAFA33] font-bold '>
						{totalBribe.toFixed(2)}
					</p>
				</span>
				<span className='flex flex-col px-2 py-2 w-fit md:w-[180px] md:min-w-[150px]'>
					<p className='mb-2 md:hidden text-white'>Votes</p>
					<p className='text-left text-[16px] text-white font-bold '>
						{totalVotes.toFixed(0)}
					</p>
				</span>
			</span>

			<span className='flex px-2 mb-3 w-full md:w-[200px] md:min-w-[180px] justify-center md:justify-start items-center'>
				<button
					className='text-white border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] cursor-fancy'
					onClick={() => {
						window.open(findChainExplorer() + buyTxn, "_blank");
					}}
				>
					<p className='text-[11px] font-bold'>TXN</p>
					<p className='text-[11px] font-bold mt-[-5px]'>Buy</p>
				</button>
				<button
					className='text-white border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0]'
					onClick={() => {
						window.open(findChainExplorer() + sellTxn, "_blank");
					}}
				>
					<p className='text-[11px] font-bold'>TXN</p>
					<p className='text-[11px] font-bold mt-[-5px]'>Sell</p>
				</button>
			</span>
		</>
	);
}
