import React, { useState, useEffect, useContext } from "react";
import api from "./api";
import isLocalHost from "./isLocalHost";

export const FaunaDatabase = React.createContext();
// hook
export function UseFaunaDatabase() {
  return useContext(FaunaDatabase);
}

export function FaunaDatabaseContext({ children }) {
  const [databaseData, setDatabaseData] = useState(null);
  const [updateDatabase, setUpdateDatabase] = useState(false);

  const readAllSessions = async () => {
    try {
      // Fetch all todos
      const sessions = await api.readAll();
      if (sessions) {
        if (sessions.message === "unauthorized") {
          if (isLocalHost()) {
            alert(
              "FaunaDB key is not unauthorized. Make sure you set it in terminal session where you ran `npm start`. Visit http://bit.ly/set-fauna-key for more info"
            );
          } else {
            alert(
              "FaunaDB key is not unauthorized. Verify the key `FAUNADB_SERVER_SECRET` set in Netlify environment variables is correct"
            );
          }
          return false;
        }
        const sessionArray = [];
        for (var i = 0; i < sessions.length; i++) {
          const session = sessions[i].data;
          // console.log(session);
          sessionArray.push(session);
        }
        // console.log("all sessions", sessions);
        setDatabaseData(sessionArray);
        return sessions;
      }
    } catch (e) {
      console.log("An API error occurred", e);
      return false;
    }
  };

  const sessionExists = async (sessionId) => {
    try {
      const sessions = await api.readById(sessionId);
      if (sessions) {
        if (sessions.message === "unauthorized") {
          if (isLocalHost()) {
            alert(
              "FaunaDB key is not unauthorized. Make sure you set it in terminal session where you ran `npm start`. Visit http://bit.ly/set-fauna-key for more info"
            );
          } else {
            alert(
              "FaunaDB key is not unauthorized. Verify the key `FAUNADB_SERVER_SECRET` set in Netlify enviroment variables is correct"
            );
          }
          return false;
        }
        if (sessions.message === "instance not found") {
          // console.log("Session Doesn't Exist");
          return false;
        }
        if (sessions.data) {
          // console.log("Session Exists", sessions.data);
          return true;
        }
      } else return false;
    } catch (e) {
      console.log("An API error occurred", e);
      return false;
    }
  };

  const addSessionData = async (newData) => {
    await api
      .create(newData)
      .then((sessions) => {
        if (sessions.message === "unauthorized") {
          if (isLocalHost()) {
            alert(
              "FaunaDB key is not unauthorized. Make sure you set it in terminal session where you ran `npm start`. Visit http://bit.ly/set-fauna-key for more info"
            );
          } else {
            alert(
              "FaunaDB key is not unauthorized. Verify the key `FAUNADB_SERVER_SECRET` set in Netlify enviroment variables is correct"
            );
          }
          return false;
        }
        // console.log("Session Created", newData);
        readAllSessions();
        return true;
      })
      .catch((e) => {
        console.log("An API error occurred", e);
        return false;
      });
  };

  const updateSessionData = async (sessionId, newData) => {
    await api
      .update(sessionId, newData)
      .then((sessions) => {
        if (sessions.message === "unauthorized") {
          if (isLocalHost()) {
            alert(
              "FaunaDB key is not unauthorized. Make sure you set it in terminal session where you ran `npm start`. Visit http://bit.ly/set-fauna-key for more info"
            );
          } else {
            alert(
              "FaunaDB key is not unauthorized. Verify the key `FAUNADB_SERVER_SECRET` set in Netlify enviroment variables is correct"
            );
          }
          return false;
        }
        // console.log("Session Updated", newData);
        readAllSessions();
        return true;
      })
      .catch((e) => {
        console.log("An API error occurred", e);
        return false;
      });
  };

  //FIXME
  // useEffect(() => {
  //   const getData = async () => {
  //     await readAllSessions();
  //   };
  //   // console.log("initial readingDatabase");
  //   getData();
  // });

  return (
    <>
      <FaunaDatabase.Provider
        value={{
          databaseData,
          sessionExists,
          addSessionData,
          updateSessionData,
        }}
      >
        {children}
      </FaunaDatabase.Provider>
    </>
  );
}
