import React, { useState, useCallback, useEffect } from "react";
import Rektxndiv from "./rektxndiv";
import { ReactComponent as TwitterlogoW } from "../public/images/Xlogo.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

import VoteTxnDiv from "./votetxndiv";
import VoteInfoHeader from "./voteInfoHeader";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";

import dclogo from "../public/images/dclogo.png";

import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";

function PopVote(props) {
	const { connected, currAddress, currChainId, wrongNetwork } = UseInfoUser();
	const { sessionEnded, tokenNative } = UseContractInfo();
	const { rektPower, setUnRektness } = UseUserPortfolio();

	const [isOpen, setIsOpen] = useState(props.openPopup);
	const txnPopup = props.txnPopup;

	const [voteVal, setVoteVal] = useState(50);
	const [confirmPopup, setConfirmPopup] = useState(false);
	const [closeConfirmPopup, setCloseConfirmPopup] = useState(false);
	const [transactionSuccess, setTransactionSuccess] = useState(null);
	const [gettingReceipt, setGettingReceipt] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [, updateState] = useState();
	const forceRender = useCallback(() => updateState({}), []);

	useEffect(() => {
		if (closeConfirmPopup) {
			setTransactionSuccess(null);
		}
	}, [closeConfirmPopup]);

	function valuetext(value) {
		return { value };
	}

	const marks = [
		{
			value: 0,
			label: "0"
		},
		{
			value: 25
		},
		{
			value: 50
		},
		{
			value: 75
		},
		{
			value: 100,
			label: "100%"
		}
	];

	function ConfirmBtnClicked() {
		setConfirmPopup(true);
	}
	function closeHasVoted() {
		setConfirmPopup(true);
	}

	async function handleVote() {
		var vote_value = ((voteVal / 100) * rektPower).toFixed(0);
		// console.log(vote_value);
		var voteArray = [];
		voteArray = {
			voteAmount: vote_value,
			submissionId: txnPopup.submissionId
		};
		const { status, message } = await props.handleVote(voteArray);
		setTransactionSuccess(status);
		setGettingReceipt(false);
		if (status === true) {
			props.success(true);
		} else {
			setErrorMessage(message);
		}
	}

	function closeConfirm() {
		setCloseConfirmPopup(true);
	}

	return (
		<>
			{isOpen && (
				<>
					{sessionEnded && (
						<>
							<div
								// fixed z-20 ml-[-1080.5px] mt-0
								className='fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80'
								onClick={(e) => {
									props.closepopup(false);
								}}
							>
								<div
									className='relative bg-[#110a26] bg-opacity-100 w-[440px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]'
									onClick={(e) => {
										// do not close modal if anything inside modal content is clicked
										e.stopPropagation();
									}}
								>
									<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-col items-center justify-center'>
										<p className='relative text-[25px] text-white font-bold py-2 mt-2'>
											Session has ended
										</p>
									</div>
									<div className='flex items-center justify-center py-8 space-x-12'>
										<button
											className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
											onClick={() => props.closepopup(false)}
										>
											<p className='text-[16px] font-bold'>Go back</p>
										</button>
									</div>
								</div>
							</div>
						</>
					)}
					{!sessionEnded && (
						<>
							<div
								className='fixed z-20 left-0 right-0 top-0 bottom-0 w-screen h-full bg-[#110a26] flex items-center justify-center bg-opacity-80'
								onClick={() => props.closepopup(false)}
							>
								<div
									className='relative bg-[#110a26] bg-opacity-100 w-full md:w-[900px] h-fit rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#ff31e9]'
									onClick={(e) => {
										// do not close modal if anything inside modal content is clicked
										e.stopPropagation();
									}}
								>
									<button
										className='z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4'
										onClick={() => props.closepopup(false)}
									>
										<p className='z-0 mt-[-2px] uppercase font-bold text-white'>
											x
										</p>
									</button>
									<div className='relative w-full border-b-[1px] border-b-gray-400 flex items-center justify-center'>
										<h1 className='relative text-[22px] md:text-[25px] text-center text-white font-bold py-8 mt-10'>
											Do you want to vote for this REKT transaction?
										</h1>
									</div>
									<div className='z-30 border-t-[2px] border-b-[2px] mt-2 border-[#26f5d8] rounded-xl max-content flex flex-col items-center justify-center'>
										<VoteInfoHeader></VoteInfoHeader>
										<div className='relative z-0 w-full'>
											<div className='pb-5 flex flex-wrap justify-start bg-[#191236] rounded-xl'>
												<VoteTxnDiv votetxndiv={txnPopup} />
												<span className='flex justify-center px-2 py-2 w-[120px] min-w-[100px]'>
													<p className='text-[16px] text-white font-bold '>
														<span className='md:hidden text-white'>
															Boost:{" "}
														</span>
														{txnPopup.boostX}x
													</p>
												</span>
											</div>
										</div>
									</div>
									<div className='relative w-full px-4'>
										<h1 className='text-center text-white border- border-white mt-8'>
											Cast vote
										</h1>
										<div className='w-full flex flex-row items-center justify-center'>
											<h1 className='text-left justify-self-start text-gray-400 text-[20px] font-thin'>
												0
											</h1>

											<Slider
												onChange={(e, val) => setVoteVal(val)}
												aria-label='Small steps'
												defaultValue={50}
												getAriaValueText={valuetext}
												step={1}
												marks={marks}
												min={-0.0}
												max={100}
												valueLabelDisplay='auto'
												color='secondary'
											/>

											<h1 className='text-gray-400 text-[20px] font-thin'>
												100%
											</h1>
										</div>
										<h1 className='text-white text-center font-bold'>
											Vote with:{" "}
											<span className='text-[#26f5d8] font-bold'>
												{((voteVal / 100) * rektPower).toFixed(0)} uREKT
											</span>
										</h1>
									</div>
									<div className='mt-5 relative w-full flex flex-wrap items-center justify-center py-8 mb-8'>
										<span className='mb-5 flex w-full items-center justify-between px-4 md:px-14'>
											<button
												className='text-white bg-[#110a26] border-[1px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => props.closepopup(false)}
											>
												<p className='text-[16px] font-bold'>Go back</p>
											</button>{" "}
											<button
												className='text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => {
													setConfirmPopup(true);
													setCloseConfirmPopup(false);
													// updateDB();
												}}
											>
												<p className='text-[16px] font-bold animate-pulse'>
													Vote
												</p>
											</button>{" "}
										</span>
										<span className='mb-5 flex w-full items-center justify-center'>
											<div className='flex flex-row items-center justify-center px-5 space-x-14'>
												<button
													className=' flex flex-row absolute x-space-2 items-center justify-center'
													onClick={() =>
														window.open(
															`https://twitter.com/intent/tweet?text=I%20am%20voting%20with%20${parseInt(
																(voteVal / 100) * rektPower
															)}%20REKT%20Power%20for%20someone%20who%20got%20REKT%20by%20${
																txnPopup.amountRekt
															}%20${tokenNative}%0A%0ALet's%20get%20UNREKT
                              %0A%0AVoters%20get%20to%20collect%20bribes!%21%20%0A%0AOnly%20at%20unrektus.fi%20%0A%0A`
														)
													}
												>
													<TwitterlogoW className='absolute h-4 w-4 hover:cursor-pointer'></TwitterlogoW>
												</button>
											</div>
											<div className='flex flex-row items-center justify-center px-5 space-x-14'>
												<button
													className='flex flex-row items-center justify-center px-5 space-x-14'
													onClick={() =>
														window.open(
															`tg://msg_url?url=unrektus.fi&text=I%20am%20voting%20with%20${parseInt(
																(voteVal / 100) * rektPower
															)}%20REKT%20Power%20for%20someone%20who%20got%20REKT%20by%20${
																txnPopup.amountRekt
															}%20${tokenNative}%0A%0ALet's%20get%20UNREKT
                              %0A%0AVoters%20get%20to%20collect%20bribes!%21%20%0A%0AOnly%20at%20unrektus.fi%20%0A%0A`
														)
													}
												>
													<TglogoW className='absolute h-4 w-4 hover:cursor-pointer'></TglogoW>
												</button>
											</div>
										</span>
									</div>
								</div>
							</div>
						</>
					)}
				</>
			)}
			{confirmPopup && !closeConfirmPopup && (
				<div
					// fixed z-20 ml-[-1080.5px] mt-0
					className='fixed z-20 left-0 top-0 w-screen h-screen bg-[#110a26] flex items-center justify-center bg-opacity-80'
					onClick={() => {
						if (transactionSuccess !== null) {
							setCloseConfirmPopup(true);
							props.closepopup(false);
						} else {
							setCloseConfirmPopup(true);
						}
					}}
				>
					<div
						className='relative bg-[#110a26] bg-opacity-100 w-[440px] h-[250px] rounded-3xl flex flex-col items-center justify-center border-[1px] border-[#26f5d8]'
						onClick={(e) => {
							// do not close modal if anything inside modal content is clicked
							e.stopPropagation();
						}}
					>
						{/* <button
              className="z-10 absolute h-6 w-6 text-gray-400 rounded-xl cursor-pointer right-4 top-4"
              onClick={() => setCloseConfirmPopup(true)}
            >
              <p className="z-0 mt-[-2px] uppercase font-bold text-white">x</p>
            </button> */}
						{rektPower > 0 ? (
							<>
								{typeof voteVal !== "undefined" && (
									<>
										<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center'>
											<p className='relative text-[25px] text-white font-bold py-2 mt-2'>
												You will vote using:&nbsp;
											</p>
											<p className='relative text-[20px] text-[#26f5d8] font-bold py-2 mt-2 '>
												{parseInt((voteVal / 100) * rektPower)}
												&nbsp; uREKT
											</p>
										</div>

										<div className='flex items-center justify-center py-8 space-x-12'>
											<button
												className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => setCloseConfirmPopup(true)}
											>
												<p className='text-[16px] font-bold'>Go back</p>
											</button>{" "}
											<button
												className='text-white bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
												onClick={() => {
													//   updateDB();
													setGettingReceipt(true);
													handleVote();
													// setCloseConfirmPopup(true);
													// props.closepopup(false);
												}}
											>
												<label className='z-20 text-[16px] font-bold animate-pulse hover:cursor-pointer'>
													Confirm
												</label>
												<div className='z-0 absolute rounded-lg h-[40px] w-[80px] border-4 blur border-[#d430b0]'></div>
											</button>
										</div>
										{gettingReceipt ? (
											<>
												<LoadingLogo className='h-[50px] w-[50px] animate-spin'></LoadingLogo>
											</>
										) : (
											<div className='flex flex-col items-center justify-center'>
												{transactionSuccess != null &&
													transactionSuccess === false && (
														<>
															<label className='z-20 text-[#d430b0] text-[16px] font-bold'>
																Transaction failed
															</label>
															<label className='z-20 text-[#d430b0] text-[16px] font-bold'>
																{errorMessage}
															</label>
														</>
													)}
												{transactionSuccess != null &&
													transactionSuccess === true && (
														<label className='z-20 text-[#26f5d8] text-[16px] font-bold '>
															Transaction success
														</label>
													)}
											</div>
										)}
									</>
								)}
							</>
						) : (
							<>
								<div className='relative w-full border-b-[1px] border-b-gray-400 flex flex-row items-center justify-center'>
									<p className='relative text-[16px] text-white font-bold py-8 mt-10'>
										You have 0 uREKT token to vote
									</p>
								</div>
								<div className='flex items-center justify-center py-8 space-x-12 mb-8'>
									<button
										className='text-white bg-[#110a26] border-[1px] ml-[10px] py-[5px] px-[20px] rounded-lg border-[#d430b0] flex items-center justify-center'
										onClick={() => {
											if (transactionSuccess !== null) {
												setCloseConfirmPopup(true);
												props.closepopup(false);
											} else {
												setCloseConfirmPopup(true);
											}
										}}
									>
										<p className='text-[16px] font-bold'>Go back</p>
									</button>{" "}
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
}
export default PopVote;
