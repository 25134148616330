import React, { useState, useEffect, useCallback } from "react";
import { Suspense } from "react";
import PopWinPot from "../components/popWinPot";
import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";

import VoteInfoHeader from "../components/voteInfoHeader";
import VoteTxnDiv from "../components/votetxndiv";
import Leaderboard from "../components/leaderDiv";
import LoadingSkeleton from "../components/loadingSkeleton";
import { ReactComponent as LoadingLogo } from "../public/images/loadingblue.svg";

export default function Leaderboards(props) {
  const {
    connected,
    currAddress,
    currChainId,
    wrongNetwork,
    comingSoon,
    changeDetected,
  } = UseInfoUser();

  const {
    initiated,
    unrektusContract,
    isLoadingStatus,
    checkStatus,
    currentStatus,
    unrektusSession,
    sessionEnded,
    tokenNative,
    lastWinner,
    winnerPicked,
    prizeValue,
    getLastWinnerInfo,
    handlePickWinner,
    handleSendWinPot,
    isLoadingData,
    leaderboardData,
  } = UseContractInfo();
  // const [lastWinner, setLastWinner] = useState(null);
  // const [winnerPicked, setWinnerPicked] = useState(false);
  // const [sendPotPushed, setSendPotPushed] = useState(false);
  // const [transactionSuccess, setTransactionSuccess] = useState(false);
  // const [potTransactionSuccess, setPotTransactionSuccess] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const [divVotes, setDivVotes] = useState([]);

  const [, updateState] = useState();
  const forceRender = useCallback(() => updateState({}), []);

  function getVotedLeaderboard() {
    // console.log(props.votedTxns);
    // console.log(leaderboardData);
    var sortedVotes = leaderboardData.sort(sortVotes);
    // console.log(sortedVotes);

    var divs = [];
    if (sortedVotes) {
      sortedVotes.forEach((votes) => {
        //push vote divs
        divs.push(
          <>
            <div
              key={sortedVotes.indexOf(votes) + 1}
              className="relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400"
            >
              <span className="mt-2 flex flex-row absolute">
                <p className="text-gray-500 text-[12px]">from:&nbsp;</p>
                <p className="text-gray-400 text-[12px]">{votes.txOwner}</p>
              </span>
              <span className="flex flex-wrap mt-[40px]">
                <span className="flex flex-col px-2 py-2 w-[60px] min-w-[40px]">
                  <p className="text-[16px] text-left text-white font-bold ">
                    <span className="md:hidden text-white">Rank: </span>
                    {sortedVotes.indexOf(votes) + 1}
                  </p>
                </span>
                <VoteTxnDiv votetxndiv={votes} />
                <span className="flex justify-center px-2 py-2 w-[120px] min-w-[100px]">
                  <p className="text-[16px] text-white font-bold ">
                    <span className="md:hidden text-white">Boost: </span>
                    {votes.boostX}x
                  </p>
                </span>
              </span>
            </div>
          </>
        );
      });
      setDivVotes(divs);
    }
  }
  function sortVotes(a, b) {
    if (a.totalVotes === b.totalVotes) return 0;
    else return a.totalVotes < b.totalVotes ? 1 : -1;
  }

  useEffect(() => {
    if (leaderboardData && leaderboardData.length > 0) {
      getVotedLeaderboard();
      // getBribeLeaderBoard();/
    }
  }, [leaderboardData]);

  // useEffect(() => {
  //   if (potTransactionSuccess === true) {
  //     const getNewPot = async () => {
  //       await checkStatus();
  //     };
  //     getNewPot();
  //   }
  // }, [potTransactionSuccess]);

  // function disableScroll() {
  //   if (sendPotPushed) {
  //     document.body.style.overflow = "hidden";
  //   }
  // }

  // function enableScroll() {
  //   if (!sendPotPushed) {
  //     document.body.style.overflow = "initial";
  //   }
  // }
  return (
    <>
      <div className="w-full mt-5 flex flex-col justify-center">
        {!connected && (
          <div className="flex flex-row justify-center">
            <h1 className="text-[#ff31e9] text-[20px] font-bold">
              Connect Wallet
            </h1>
          </div>
        )}
        {comingSoon && (
          <div className="flex flex-row justify-center ">
            <div className=" flex flex-col justify-center mb-[50px] ">
              <h1 className="text-[#23ff7b] text-[20px] font-bold">
                Coming Soon on this chain
              </h1>
            </div>
          </div>
        )}
        {connected && !comingSoon && !wrongNetwork && (
          <>
            {/* {sendPotPushed && (
              <PopWinPot
                closepopup={setSendPotPushed}
                openPopup={sendPotPushed}
                handleSendPot={handleSendWinPot}
                success={setTransactionSuccess}
              >
                {disableScroll()}
              </PopWinPot>
            )}
            {!sendPotPushed && enableScroll()} */}
            <div className="w-full flex flex-col items-center justify-center">
              {/* <div className="border-b-2 flex flex-col w-full items-center justify-center border-white rounded-3xl py-10 bg-[#080613]/40"> */}
              <div className="flex flex-col items-center justify-center ">
                <div className="relative border-[2px] border-white rounded-xl py-3 bg-[#080613]/60 top-0 px-8 flex items-center justify-center ">
                  <button className="text-[#26f5d8] text-2xl md:text-3xl uppercase font-extrabold ">
                    Leaderboard&nbsp;
                  </button>
                </div>
              </div>

              {isLoadingStatus && (
                <div className="w-full flex flex-col items-center justify-center">
                  <LoadingLogo className="mt-12 h-[50px] w-[50px] animate-spin"></LoadingLogo>
                  <p className="text-white">Loading Status...</p>
                </div>
              )}

              {!isLoadingStatus && currentStatus && (
                <>
                  {/* <div className="flex flex items-center justify-center px-2 py-2">
                    <h1 className="text-[25px] text-[#ff31e9] font-bold">
                      Prize:&nbsp;&nbsp;
                    </h1>
                    <h1 className="text-[25px] text-[#26f5d8] font-bold animate-pulse">
                      {prizeValue}&nbsp;{tokenNative}
                    </h1>
                  </div> */}
                  {prizeValue <= 0 && (
                    <>
                      <div className="flex flex-col items-center justify-center px-2 py-2">
                        <h1 className="mb-2 mt-[-10px] text-center text-[16px] text-[#ff4940]">
                          *Winning pot is empty
                        </h1>
                        {/* {currAddress === currentStatus._rektMasterAddress && (
                          <button
                            className="w-[300px] text-center text-[#26f5d8] text-[20px] uppercase font-extrabold border-[1px] border-[#ff31e9] px-2 py-1 rounded-lg"
                            onClick={() => {
                              setSendPotPushed(true);
                            }}
                          >
                            Send Pot Money
                          </button>
                        )} */}
                      </div>
                    </>
                  )}

                  {lastWinner && (
                    <>
                      <div className="flex flex-col items-center pb-2 mt-10 mb-2 justify-center">
                        <h1 className="text-[20px] text-[#26f5d8] font-bold">
                          Last Winner:&nbsp;&nbsp;
                          <span className="text-[#ff31e9]">
                            Session {lastWinner.sessionId}
                          </span>
                        </h1>
                        <div className="mt-5 relative flex flex-row w-full items-center justify-center">
                          <div className="relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg px-1">
                            <div className="z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg">
                              <VoteInfoHeader></VoteInfoHeader>
                              <div className="relative z-0 w-full">
                                <div className="relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100">
                                  <span className="mt-2 flex flex-row absolute left-[10px]">
                                    <p className="text-gray-500 text-[12px]">
                                      from:&nbsp;
                                    </p>
                                    <p className="text-white text-[12px]">
                                      {lastWinner.txOwner}
                                    </p>
                                  </span>
                                  <span className="flex flex-wrap mt-[40px]">
                                    <VoteTxnDiv votetxndiv={lastWinner} />
                                    <span className="flex justify-center px-2 py-2 w-[120px] min-w-[100px]">
                                      <p className="text-[16px] text-white font-bold ">
                                        <span className="md:hidden text-white">
                                          Boost:{" "}
                                        </span>
                                        {lastWinner.boostX}x
                                      </p>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {isLoadingData ? (
                    <div className="w-full mt-10 flex flex-col items-center justify-center">
                      <LoadingSkeleton />
                    </div>
                  ) : (
                    <>
                      <div className="flex items-center pb-2 mt-10 mb-2 justify-center">
                        <h1 className="text-[20px] text-[#ff31e9] font-bold">
                          Current session: &nbsp;&nbsp;
                        </h1>
                        {!sessionEnded ? (
                          <h1 className="text-[20px] text-[#26f5d8] font-bold">
                            {unrektusSession}
                          </h1>
                        ) : (
                          <h1 className="text-[20px] text-[#26f5d8] font-bold">
                            # {unrektusSession} &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="text-[#ff4940] animate-pulse">
                              ENDED
                            </span>
                          </h1>
                        )}
                      </div>
                      <div className="px-3 py-3 w-full flex flex-col items-center justify-center">
                        <Leaderboard div={divVotes} />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
