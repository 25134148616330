import React from "react";
import { useState, useEffect, useCallback, updateState } from "react";
import { ethers } from "ethers";
import { MdOutlineRocketLaunch } from "react-icons/md";

import VoteTxnDiv from "../components/votetxndiv";
import VoteInfoHeader from "../components/voteInfoHeader";
import PopVote from "../components/popVote";
import PopBribe from "../components/popBribe";
import PopWithdraw from "../components/popWithdraw";
import PopBoost from "../components/popBoost";
import { ReactComponent as TwitterlogoW } from "../public/images/Xlogo.svg";
import { ReactComponent as TglogoW } from "../public/images/tglogowhite.svg";

import { UseInfoUser } from "../web3/Web3Context";
import { UseContractInfo } from "../web3/ContractContext";
import { UseUserPortfolio } from "../web3/UserPortfolio";
import LoadingSkeleton from "../components/loadingSkeleton";

export default function Vote(props) {
	const { connected, currAddress, currChainId, wrongNetwork, comingSoon } =
		UseInfoUser();
	const {
		initiated,
		currentStatus,
		tokenNative,
		unrektusSession,
		sessionEnded,
		handleVote,
		handleBribe,
		handleWithdrawBribe,
		handleBoost,
		handleClaimBribe,
		checkUserVotesInfo,
		isLoadingData,
		getSubmissionPropertiesAll,
		submissionDetails
	} = UseContractInfo();
	const {
		rektPower,
		getRektPower,
		setUnRektness,
		userCurrentVotes,
		getUserPastVotes
	} = UseUserPortfolio();

	const [voteBtnPushedvar, setVoteBtnPushedvar] = useState(false);
	const [bribeBtnPushedvar, setBribeBtnPushedvar] = useState(false);
	const [withdrawBribevar, setWithdrawBribevar] = useState(false);
	const [boostBtnPushvar, setbBoostBtnPushvar] = useState(false);

	const [selectedTxn, setSelectedTxn] = useState(null);
	const [transactionIsSuccess, setTransactionIsSuccess] = useState(false);

	const [voteDivs, setVoteDivs] = useState([]);

	async function getUserCurrentVotes() {
		// console.log("get user Vote Data");
		const { userCurrentVotes, allVotes } = await getUserPastVotes();
		// console.log(userCurrentVotes, allVotes);
		return userCurrentVotes;
	}

	function getChainName(chainId) {
		switch (chainId) {
			case "0x1":
				return "Ethereum";
			case "0xfa":
				return "FantomSonic";
			case "0x440":
				return "Metis";
			case "0x7d0":
				return "Dogechain";
			case "0x238":
				return "Dogechain Testnet";
			case "0x7a69":
				return "Localhost";
			default:
				return "Unknown";
		}
	}

	function checkUserVoted(submission, userVotes) {
		var condition = false;
		userVotes.forEach((vote) => {
			if (vote.submissionId === submission.submissionId) {
				// console.log(submission);
				// console.log(vote);
				condition = true;
				return true;
			}
		});
		return condition;
	}

	function getVoteDivs(allSubmittedTxn, userVotes) {
		// console.log("get Vote Divs");
		var total = [];
		var owner = [];
		var rest = [];

		if (allSubmittedTxn.length > 0) {
			var arr = allSubmittedTxn.forEach((submission) => {
				// console.log(submission.txOwner, currAddress);

				// console.log(submission.voteReceived, submission.totalBribe);
				//add to leaderboard;

				const boostX = submission.boostX;
				//alreadyBribe (bribe can be set once)
				var bribeSet = false;
				if (submission.totalBribe > 0) {
					bribeSet = true;
				}
				var canWithdraw = true;
				if (submission.totalVotes > 0) {
					canWithdraw = false;
				}
				var voted = false;

				if (userVotes.length > 0) {
					voted = checkUserVoted(submission, userVotes);
					// console.log("voted", voted);
				}

				if (currAddress.toUpperCase() === submission.txOwner.toUpperCase()) {
					owner.push(
						<div
							key={submission.submissionId}
							className='relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400'
						>
							<span className='mt-2 flex flex-row absolute'>
								<p className='text-gray-500 text-[12px]'>from:&nbsp;</p>
								<p className='text-[#26f5d8] text-[12px]'>
									{submission.txOwner}
								</p>
							</span>
							<span className='flex flex-wrap mt-[40px]'>
								<VoteTxnDiv handleClick={handleClick} votetxndiv={submission} />
								<span className=' flex flex-row mb-3 w-full md:w-[240px] md:min-w-[200px] justify-center items-center'>
									<span className='flex justify-center px-2 py-2 w-[120px] min-w-[100px]'>
										{!sessionEnded && submission.boostX <= 1 ? (
											<button
												className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
												onClick={() => {
													handleClick("boost", submission);
												}}
											>
												{"BOOST"}
											</button>
										) : (
											<p className='flex flex-inline text-[16px] text-lime-400 font-bold items-center'>
												<MdOutlineRocketLaunch /> &nbsp;
												<span className='md:hidden text-lime-400'>Boost: </span>
												{submission.boostX}x
											</p>
										)}
									</span>
									<span className='flex flex-row justify-center items-center px-2 py-2 w-[120px] min-w-[100px]'>
										{!bribeSet ? (
											<>
												{!sessionEnded && (
													<button
														className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
														onClick={() => {
															handleClick("bribe", submission);
														}}
													>
														{"Set Bribe"}
													</button>
												)}
											</>
										) : (
											<>
												{!sessionEnded && (
													<button
														className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
														onClick={() => {
															handleClick("bribe", submission);
														}}
													>
														{"Add Bribe"}
													</button>
												)}
												{sessionEnded && (
													<>
														{canWithdraw ? (
															<button
																className='text-white border-[1px] py-[5px] px-[5px] rounded-lg border-[#26f5d8]'
																onClick={() => {
																	handleClick("withdraw", submission);
																}}
															>
																<p className='text-[11px] font-bold'>
																	Withdraw
																</p>
																<p className='text-[11px] font-bold mt-[-5px]'>
																	Bribe
																</p>
															</button>
														) : (
															<label className='text-[#26f5d8] animate-pulse border-[#26f5d8] border-b-[1px]'>
																Bribe Set
															</label>
														)}
													</>
												)}
											</>
										)}
									</span>
								</span>
							</span>
							<div className='mt-4 mr-8 flex flex-col space-x-8'>
								<div>
									<button
										className=' flex flex-row absolute x-space-2 items-center justify-center'
										onClick={() =>
											window.open(
												`tg://msg_url?url=unrektus.fi&text=%0AThe%20pleb%20is%20getting%20REKT%20on%20${getChainName(
													currChainId
												)}!%0A%0A${submission.txOwner}%0AGot%20REKT%20by%20${
													submission.amountRekt
												}%20${tokenNative}%0AVote%20for%20Submission%20ID:%20${
													submission.submissionId
												}%20%0ABribe:%20${
													submission.totalBribe
												}%20${tokenNative}%0ACurrent%20vote%20count:%20${
													submission.totalVotes
												}%0A%0ALet's%20get%20UNREKT%21%20%0A%0AOnly%20at%20unrektus.fi/vote%0A%0A&hashtags=unrektus`
											)
										}
									>
										<TglogoW className='absolute h-4 w-4'></TglogoW>
									</button>
								</div>
								<div>
									<button
										className=' flex flex-row absolute items-center justify-center'
										onClick={() =>
											window.open(
												`https://twitter.com/intent/tweet?text=The%20pleb%20is%20getting%20REKT%20on%20${getChainName(
													currChainId
												)}!%0A%0A${submission.txOwner}%0AGot%20REKT%20by%20${
													submission.amountRekt
												}%20${tokenNative}%0AVote%20for%20Submission%20ID:%20${
													submission.submissionId
												}%20%0ABribe:%20${
													submission.totalBribe
												}%20${tokenNative}%0ACurrent%20vote%20count:%20${
													submission.totalVotes
												}%0A%0ALet's%20get%20UNREKT%21%20%0A%0AOnly%20at/vote%0A%0A&hashtags=unrektus`
											)
										}
									>
										<TwitterlogoW className='absolute h-4 w-4'></TwitterlogoW>{" "}
									</button>
								</div>
							</div>
						</div>
					);
				} else {
					rest.push(
						<div
							key={submission.submissionId}
							className='relative pb-5 flex flex-wrap justify-start bg-[#191236] opacity-100  border-b-[1px] border-gray-400'
						>
							<span className=' mt-2 flex flex-row absolute'>
								<p className='text-gray-500 text-[12px]'>from:&nbsp;</p>
								<p className='text-[#d430b0] text-[12px]'>
									{submission.txOwner}
								</p>
							</span>
							<span className='flex flex-wrap mt-[40px]'>
								<VoteTxnDiv handleClick={handleClick} votetxndiv={submission} />
								<span className='flex flex-row mb-3 w-full md:w-[240px] md:min-w-[200px] justify-center items-center'>
									<span className='flex justify-center px-2 py-2 w-[120px] min-w-[100px]'>
										{submission.boostX > 1 ? (
											<p className='flex flex-inline text-[16px] text-lime-400 font-bold  items-center'>
												<MdOutlineRocketLaunch />
												&nbsp;
												<span className='md:hidden text-lime-400'>Boost: </span>
												{submission.boostX}x
											</p>
										) : (
											<p className='text-[16px] text-white font-bold '>
												<span className='md:hidden text-white'>Boost: </span>
												{submission.boostX}x
											</p>
										)}
									</span>
									<span className='flex justify-center items-center px-2 py-2 w-[120px] min-w-[100px]'>
										{voted === true ? (
											<>
												{!sessionEnded && (
													<button
														className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
														onClick={() => {
															handleClick("vote", submission);
														}}
													>
														{"Add Vote"}
													</button>
												)}
											</>
										) : (
											<>
												{!sessionEnded && (
													<button
														className='text-white text-[13px] bg-gradient-to-r via-[#d430b0] to-[#962ee4] from-pink-600 rounded-lg py-[7.5px] px-[20px] font-bold hover:text-gray-200'
														onClick={() => {
															handleClick("vote", submission);
														}}
													>
														{"Vote"}
													</button>
												)}
											</>
										)}
									</span>
								</span>
							</span>
							<div className='mt-4 mr-8 flex flex-col space-x-8'>
								<div>
									<button
										className=' flex flex-row absolute x-space-2 items-center justify-center'
										onClick={() =>
											window.open(
												`tg://msg_url?url=unrektus.fi&text=%0AThe%20pleb%20is%20getting%20REKT%20on%20${getChainName(
													currChainId
												)}!%0A%0A${submission.txOwner}%0AGot%20REKT%20by%20${
													submission.amountRekt
												}%20${tokenNative}%0AVote%20for%20Submission%20ID:%20${
													submission.submissionId
												}%20%0ABribe:%20${
													submission.totalBribe
												}%20${tokenNative}%0ACurrent%20vote%20count:%20${
													submission.totalVotes
												}%0A%0ALet's%20get%20UNREKT%21%20%0A%0AOnly%20at%20unrektus.fi/vote%0A%0A&hashtags=unrektus`
											)
										}
									>
										<TglogoW className='absolute h-4 w-4'></TglogoW>
									</button>
								</div>
								<div>
									<button
										className=' flex flex-row absolute items-center justify-center'
										onClick={() =>
											window.open(
												`https://twitter.com/intent/tweet?text=The%20pleb%20is%20getting%20REKT%20on%20${getChainName(
													currChainId
												)}!%0A%0A${submission.txOwner}%0AGot%20REKT%20by%20${
													submission.amountRekt
												}%20${tokenNative}%0AVote%20for%20Submission%20ID:%20${
													submission.submissionId
												}%20%0ABribe:%20${
													submission.totalBribe
												}%20${tokenNative}%0ACurrent%20vote%20count:%20${
													submission.totalVotes
												}%0A%0ALet's%20get%20UNREKT%21%20%0A%0AOnly%20at%20unrektus.fi/vote%0A%0A&hashtags=unrektus`
											)
										}
									>
										<TwitterlogoW className='absolute h-4 w-4'></TwitterlogoW>{" "}
									</button>
								</div>
							</div>
						</div>
					);
				}
			});

			total.push(owner);
			// total.push(
			//   <>
			//     <div
			//       className="flex h-[5px] bg-[#191236] opacity-100  border-b-[3px] border-gray-400"
			//       key="0"
			//     ></div>
			//   </>
			// );
			total.push(rest);

			setVoteDivs(total);
		}
	}

	const handleClick = (type, submission) => {
		if (type === "bribe") {
			setBribeBtnPushedvar(true);
			setSelectedTxn(submission);
			setTransactionIsSuccess(null);
		} else if (type === "vote") {
			setVoteBtnPushedvar(true);
			setSelectedTxn(submission);
			setTransactionIsSuccess(null);
		} else if (type === "withdraw") {
			setWithdrawBribevar(true);
			setSelectedTxn(submission);
			setTransactionIsSuccess(null);
		} else if (type === "boost") {
			setbBoostBtnPushvar(true);
			setSelectedTxn(submission);
			setTransactionIsSuccess(null);
		}
	};

	const getData = async () => {
		getVoteDivs(submissionDetails, userCurrentVotes);
	};

	useEffect(() => {
		if (userCurrentVotes !== null && submissionDetails !== null) {
			setVoteDivs([]);
			getData();
		}
	}, [userCurrentVotes, submissionDetails]);

	useEffect(() => {
		if (transactionIsSuccess === true) {
			getRektPower();
			// setDataReady(null);
			setVoteDivs([]);
			getUserCurrentVotes();
			getSubmissionPropertiesAll();
			getData();
		}
	}, [transactionIsSuccess]);

	function disableScroll() {
		if (
			voteBtnPushedvar ||
			bribeBtnPushedvar ||
			withdrawBribevar ||
			boostBtnPushvar
		) {
			document.body.style.overflow = "hidden";
		}
	}

	function enableScroll() {
		if (
			!voteBtnPushedvar &&
			!bribeBtnPushedvar &&
			!withdrawBribevar &&
			!boostBtnPushvar
		) {
			document.body.style.overflow = "initial";
		}
	}
	return (
		<>
			<div className='mt-5 w-full flex flex-col justify-center'>
				{!connected && (
					<div className=' flex flex-row justify-center'>
						<h1 className='text-[#ff31e9] text-[20px] font-bold'>
							Connect Wallet
						</h1>
					</div>
				)}
				{comingSoon && (
					<div className='flex flex-row justify-center '>
						<div className=' flex flex-col justify-center mb-[50px] '>
							<h1 className='text-[#23ff7b] text-[20px] font-bold'>
								Coming Soon on this chain
							</h1>
						</div>
					</div>
				)}
				{connected && !comingSoon && !wrongNetwork && (
					<>
						{/* <Leaderboard votedTxns={allVotedTxn} bribedTxns={allBribedTxn} /> */}

						{voteBtnPushedvar && (
							<PopVote
								closepopup={setVoteBtnPushedvar}
								openPopup={voteBtnPushedvar}
								txnPopup={selectedTxn}
								handleVote={handleVote}
								success={setTransactionIsSuccess}
							>
								{/* {console.log(rektdivVotevar)} */}
								{disableScroll()}
							</PopVote>
						)}
						{!voteBtnPushedvar && enableScroll()}
						{bribeBtnPushedvar && (
							<PopBribe
								closepopup={setBribeBtnPushedvar}
								openPopup={bribeBtnPushedvar}
								txnPopup={selectedTxn}
								handleBribe={handleBribe}
								success={setTransactionIsSuccess}
							>
								{/* {console.log(rektdivVotevar)} */}
								{disableScroll()}
							</PopBribe>
						)}
						{!bribeBtnPushedvar && enableScroll()}
						{withdrawBribevar && (
							<PopWithdraw
								closepopup={setWithdrawBribevar}
								openPopup={withdrawBribevar}
								txnPopup={selectedTxn}
								handleWithdraw={handleWithdrawBribe}
								success={setTransactionIsSuccess}
							>
								{/* {console.log(rektdivVotevar)} */}
								{disableScroll()}
							</PopWithdraw>
						)}
						{!withdrawBribevar && enableScroll()}
						{boostBtnPushvar && (
							<PopBoost
								closepopup={setbBoostBtnPushvar}
								openPopup={boostBtnPushvar}
								txnPopup={selectedTxn}
								handleBoost={handleBoost}
								success={setTransactionIsSuccess}
							>
								{/* {console.log(rektdivVotevar)} */}
								{disableScroll()}
							</PopBoost>
						)}
						{!withdrawBribevar && enableScroll()}

						<div className='px-3 py-3 w-full flex flex-col items-center justify-center'>
							<div className='flex flex-col items-center justify-center'>
								{/* <div className="mt-10 flex flex-row items-center justify-center"> */}
								<h1 className='text-[#26f5d8] text-2xl uppercase font-extrabold	'>
									Submissions
								</h1>
								<h1 className='text-[#ff31e9] text-2xl uppercase font-extrabold'>
									VOTE&nbsp;&nbsp; BRIBE&nbsp; &nbsp;BOOST!
								</h1>
								{/* </div> */}
							</div>
							{voteDivs.length > 0 ? (
								<>
									<div className='mt-10 flex-col items-center justify-center'>
										{/* <div className="relative hidden md:flex flex-row w-full items-center justify-center">
                      <div className="relative flex flex-col items-center rounded-lg py-1 px-1">
                        <div className="z-10 relative px-5 flex flex-col items-center w-full border-[12px] border-[#110a26] rounded-lg">
                          
                        </div>
                      </div>
                    </div> */}
										<div className='relative flex flex-row w-full items-center justify-center'>
											<div className='relative flex flex-col items-center bg-gradient-to-r from-white to-[#26f5d8] via-blue-300 rounded-lg py-1 px-1'>
												<div className='z-10 relative flex flex-col items-center w-full border-[8px] bg-[#110a26] border-[#110a26] rounded-lg'>
													<VoteInfoHeader></VoteInfoHeader>
													<div className='relative z-0 w-full'>{voteDivs}</div>
												</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<>
									{isLoadingData ? (
										<div className='w-full mt-10 flex flex-col items-center justify-center'>
											<LoadingSkeleton />
										</div>
									) : (
										<div className='flex flex-col items-center justify-center'>
											<h1 className='text-white text-[20px] font-bold'>
												No submissions yet!
											</h1>
										</div>
									)}
								</>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
}
